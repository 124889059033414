@import "../../../styles/main.scss";
.confirmation-body{
  margin: 1em;
  align-items: flex-start;
  display: flex;
  font-size: 1.5em;
}
.header-container{
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
}
.comment-body{
  margin-bottom: 2em;
  align-items: flex-start;
  display: flex;
  font-size: 1.5em;
}
 .comment{
   max-width: 40%;
   text-align: justify;
   word-wrap: break-word
 }
.transactions-table-row {
  margin-top: 0.5rem;
  &.pending {
    color: $icon-warning !important;
    font-size: 16px;
  }
  &.rejected {
    color: $icon-error !important;
    font-size: 16px;
  }

  &.approved {
    color: $nexus-primary-green !important;
    font-size: 16px;
  }

  &.details {
    color: #8d8d8d !important;
    font-size: 16px;
    text-decoration: underline;
  }
}