@import "../../../styles/main.scss";

.registrar-sign-up-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  width: 100%;
  background-color: white;

  .nexus-header {
    position: relative;
    display: flex;
    align-items: center;
    height: 129px;
    width: 100%;

    .background-header {
      height: 129px;
      width: 100%;
    }

    .header-logo {
      position: absolute;
      height: 31px;
      width: 156px;
      margin-left: 83px;
    }
  }
}

.account-created-successful-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
  padding-right: 50px;
  padding-left: 50px;
  background-image: linear-gradient(to bottom, $nexus-primary-green, $nexus-secondary-green);

  .activation-instructions-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 657px;
    width: 100%;
    height: auto;
    border-radius: 13px;
    background-color: #ffffff;
    margin-top: 38px;
    padding-left: 20px;
    padding-right: 20px;

    .icon-check {
      font-size: 33px;
      margin-top: 35px;
      color: #2aab9a;
    }

    .title {
      margin-top: 35px;
      font-family: $font-Comfortaa;
      font-size: 26px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.85;
      letter-spacing: -0.63px;
      text-align: center;
      color: #323133;
    }

    .paragraph-container {
      margin: 18px 78px 58px 79px;

      .paragraph {
        font-family: $font-Comfortaa;
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.22;
        letter-spacing: -0.43px;
        text-align: center;
        color: #323133;

        &.last {
          margin-top: 15px;
        }
      }
    }

    @media (max-width: 688px) {
      .paragraph-container {
        margin: 18px;
      }
    }
  }

  .back-to-top {
    font-family: $font-Comfortaa;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: normal;
    color: #ffffff;
    text-decoration: underline;
    margin-top: 40px;

    &:hover {
      cursor: pointer;
    }
  }
}