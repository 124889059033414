@import '../../../styles/styles.scss';

.error-box {
  width: 100%;
  border-radius: 5px;
  display: none;
  justify-content: center;
  align-items: center;
  border: solid 1px $error-main;
  background-color: $error-secondary;
  padding: 10px;

  &.notification-wallet-success {
    border: solid 1px #057a83;
    background-color: rgba(44, 176, 149, 0.11);

    .error-msj {
      font-size: 12px;
      line-height: 1.25;
      text-align: center;
      color: #057a83;
    }
  }

  &.notification-wallet-error {
    .error-msj{
      font-size: 12px;
      line-height: 1.25;
      text-align: center;
      color: $decline-color-light;
    }
  }
  &.notification-wallet-loading {
    border: solid 1px $engave-color-dark;
    background-color:$engave-color-transparent;
    .error-msj{
      font-size: 12px;

      line-height: 1.25;
      text-align: center;
      color: $engave-color-dark;
    }
  }

  &.show {
    display: flex !important;
    flex-direction: column;
  }

  i {
    //display: block;
    color: $icon-error;
    font-size: 20px;
    margin-right: 21px;
  }

  .error-msj {
    font-family: $font-Comfortaa;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: -0.39px;
    text-align: center;
    color: #323133;
  }
  .details-link{
    display: flex;
    align-items: center;
    margin-top:5px;
    font-family: $font-Comfortaa;
    font-size: 15px;
    color: #323133;
  }
}