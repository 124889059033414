@import '../../../../styles/styles.scss';

.card-body-modal {
  padding: 80px 0 80px 0;
}

.info-addressee-modal-background {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 999;

  background-color: rgb(0, 0, 0, 0.4);
  padding: 0;

  .info-addressee-modal {
    max-width: 863px;

    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 21px;
  }

  .container-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    font-family: Comfortaa;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    color: $font-main-color;

    .body-dialog {
      width: 500px;

      .padding-name {
        padding-top: 16px;
      }

      .info-addressee-container {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
        padding: 14px 0 34px 0;

        &.style-text {
          color: $title-color  !important;
          font-size: 16px;
        }
      }

      .chk-container {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;

        .style-text-chk {
          color: $title-color  !important;
          font-size: 14px;
          font-weight: 600;
        }
      }

      .btn-container {
        width: 100%;
        padding-top: 20px;
      }

      .wrapper-input {
        // max-width: 282px;
        height: 46px;

        border-radius: 7px;
        box-shadow: 0 4px 30px 0 $wallet-shadow;

        .style-text {
          font-size: 20px;
          font-weight: 600;
          line-height: 1.2;
          text-align: center;
          color: $font-main-color;

        }
      }
    }

    .input-bow-height {
      max-width: 500px
    }
  }
}
