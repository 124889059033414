@import "../../../styles/main.scss";

.select-multiple-container {
  display: flex;

  .select-multiple {
    position: relative;
    display: flex;
    align-items: center;
    padding: 10px 5px 10px 5px;
    border: solid 1px #dfe2e6;
    border-radius: 4px;
    background-color: #ffffff;

    .select-multiple-placeholder {
      font-family: $font-Comfortaa;
      font-size: 16px;
      font-weight: 600;
      color: $title-color;
    }

    i {
      margin-left: 15px;
    }

    &.rounded {
      height: 38px;
      border-radius: 30px;
      padding: 0 15px 0 15px;
    }
  }

  .select-multiple:hover {
    cursor: pointer;
  }

  .select-multiple-options-container {
    position: absolute;
    z-index: 999;
    display: none;
    padding: 27px 20px 27px 20px;
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: 0 2px 14px 0 rgba(118, 118, 118, 0.5);
    margin-top: 44px;
    margin-right: 15px;

    &.show {
      display: flex;
      flex-direction: column;
    }

    .select-multiple-option {
      display: flex;
      align-items: center;

      .regular-checkbox {
        margin-right: 15px;
        width: auto;
        background-color: #ffffff;
      }

      .select-multiple-option-label {
        font-family: $font-Comfortaa;
        font-size: 16px;
        font-weight: 600;
        color: #021516;
      }
    }
    .select-multiple-option:hover {
      cursor: pointer;
    }
  }
}
