@import "../../../styles/main.scss";
.registrar-institution-card {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 7px;
  box-shadow: 0 2px 4px 0 rgba(228, 228, 228, 0.5);
  padding: 13px 42px 27px 48px;
  max-width: 1091px;
  margin-left: 20px;
  margin-right: 20px;

  .active-key-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;
    border-radius: 5px;
    border: solid 1px #58beab;
    background-color: #d1ece6;
    margin-bottom: 23px;
    margin-top: 5px;

    i {
      -webkit-text-stroke: 1px #055f62;
      font-size: 20px;
      color: #0e9997;
      margin-right: 10px;
    }
    span {
      font-family: $font-Comfortaa;
      font-size: 16px;
      font-weight: bold;
      color: #055f62;
    }
  }

  .title {
    font-family: $font-CoconPro;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4a4a4a;
    margin-bottom: 18px;
  }

  img {
    width: 100%;
  }

  .fix-height {
    height: 44px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .inputs-container {
    display: flex;
    flex-direction: column;
    margin-top: 40px;

    &.with-error {
      margin-top: 0;
    }

    .inputs-row {
      display: flex;
      flex-wrap: wrap;

      .input-wrapper {
        display: flex;
        flex: 1;
        flex-direction: column;
        margin-right: 22px;
        min-width: 250px;
      }

      .input-wrapper:first-child {
        margin-left: 0;
      }
    }

    .change-password {
      display: flex;
      justify-content: flex-start;

      i {
        font-size: 18px;
        color: #656565;
        margin-right: 9px;
      }

      span {
        font-family: $font-Comfortaa;
        font-size: 15px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-decoration: underline;
        color: #5a5a5a;
      }

      span:hover {
        cursor: pointer;
      }
    }
  }

  .button {
    align-self: center;
    margin-top: 20px;
  }

  @media (max-width: 400px) {
    padding: 13px 20px 27px 20px;
  }
}