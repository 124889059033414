@import '../../../styles/styles.scss';

.header {
    padding: 0 0;
    width: 100%;
    height: 101px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    background-color: white;
    &.main-header {
        min-width: 100vw;
    }

    .dashboard {
        display: flex;
        width: 105px;
        height: 100%;
        align-items: center;
        justify-content: center;

        a {
            text-decoration: none;
        }

        i {
            font-size: 28px;
            color: #b9b9b9;
            cursor: pointer;

            &.selected {
                color: $nexus-primary-green;
            }
        }
    }

    .logo {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex: 10;
    }

    .profile-button {
        display: flex;
        flex: 1;
        align-items: center;
        border-left: solid 1px $gray;

        .name-container {
            display: flex;
            flex-direction: column;

            span {
                font-family: $font-Comfortaa;
                font-size: 14px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: $title-color;
            }
        }

        i {
            margin-left: 22px;
            margin-right: 50px;
        }

        img {
            width: 56px;
            height: 56px;
            border-radius: 50px;
            margin-left: 15px;
            margin-right: 15px;
            object-fit: cover;
        }
    }
    @media (max-width: 768px) {
        .dashboard {
            display: none;
        }
        .profile-button {
            border: none;
        }
        .profile-button {
            i {
                margin-right: 20px;
            }
        }
    }

    > button {
        display: flex;
        border: 1px solid #008e99;
        margin-right: 16px;
    }
}
