@import "../../../styles/main.scss";

.transactions-success-modal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 77px 142px 74px 142px;

  i {
    color: $icon-success;
    font-size: 54px;
  }

  .modal-body-text {
    font-family: $font-Comfortaa;
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: normal;
    text-align: center;
    color: $title-color;
    margin-top: 21px;
  }

  .button {
    margin-top: 44px;
  }
}
