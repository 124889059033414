@import "../../../styles/styles.scss";

.body-input-box {
  height: 156px;
  max-width: 480px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;

  padding: 2em;
  border-radius: 7px;
  box-shadow: 0 4px 30px 0 $wallet-shadow;
  border: solid 1px rgba(5, 122, 131, 0.18);
  background-color: $wallet-color-white;

  position: relative;

  .title-box {
    font-family: Comfortaa;
    font-size: 10px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 3px;
    text-align: left;
    color: $title-color;
  }
}

.amount {
  padding: 10px;
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  position: relative;

  font-family: Comfortaa;
  font-size: 40px;
  font-weight: 600;
  letter-spacing: 3px;
  color: $title-color;


  .editBox {
    width: 35px;
    height: 100%;
    border: none;
    background-color: transparent;

    font-size: 43px;
    font-weight: 600;
    text-align: center;
    letter-spacing: 3px;
    color: $title-color;
  }

  .editBox:focus {
    outline: none;
  }

  .wrapperChildren {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;

    padding: 4px 0 0 0;
  }
}