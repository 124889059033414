@import '../../../../styles/styles.scss';

.new-user-button-container {
  padding-top: 20px;
}
.new-user-button {
  box-shadow: 0 10px 20px 0 rgb(210, 239, 233);
  border-radius: 20px;
  background-image: $nexus-gradient-green;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &.big {
    width: 300px;
    height: 50px;
  }

  &.small {
    margin-top: 4%;
    width: 160px;
    height: 40px;
  }

  &.cancel {
    background-image: $decline-color-gradient
  }
}
.new-user-modal {
  width: 45%;
  height: 35%;
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  padding: 0 0 50px 0;
  transform: translate(-50%, -50%);
  border-radius: 21px;
  background-color: rgb(255, 255, 255);
  margin: 0;

  &.small {
    height: 35%;
  }

  &.big {
    height: 45%;
  }

  &.delete {
    height: 33%;
  }
}

.new-user-modal-children-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 50px 30px 40px 25px;
  height: 100%;

  &.show-message {
    padding: 20px 30px 40px 20px;

  }
}

.new-user-modal-background {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 999;
  background-color: rgb(0, 0, 0, 0.4)
}

.close-modal-container {
  cursor: pointer;
  margin-left: 5px;
  margin-bottom: 5px;
}

.new-user-header {
  width: 100%;
  font-family: Comfortaa, serif;
  font-size: 20px;
  font-weight: 600;
  text-align: left;
  color: rgb(52, 67, 68);
  margin-bottom: 10px;
}

.modal-card-head {
  padding: 0.3em 1em 0 0.5em;
  background: $nexus-gradient-green;
  border-top-right-radius: 21px;
  border-top-left-radius: 21px;

  &.red {
    height: 2em;
    background-image: $decline-color-gradient
  }
}

.delete-modal-question {
  text-align: center;
  font-family: Comfortaa, serif;
  font-size: 20px;
  font-weight: 600;
  color: rgb(52, 67, 68);
}

.wrapper-notification-modal {
  width: 100%;
  padding-top: 10px;
}