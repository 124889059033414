@import '../../../../../styles/main.scss';

.audit-history-button {
    height: 3rem;
    background: none;
    border: none;
    color: $nexus-primary-green;
    font-family: $font-Comfortaa;
    font-weight: 900;
    text-decoration: underline;
    transition-duration: 200ms;
    transition-timing-function: ease-in-out;
}
.audit-history-button:hover {
    color: $nexus-secondary-green;
}
.audit-history-button:focus {
    border: none;
}

.comment-container {
    display: flex;
    flex-flow: row;
    gap: 8px;

    > p {
        font-family: 'Roboto', sans-serif;
    }
}
