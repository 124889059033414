@import '../../../styles/styles.scss';

.success-box {
  width: 100%;
  height: 40px;
  border-radius: 7px;
  border: solid 1px #057a83;
  background-color: rgba(91, 250, 219, 0.11);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 10px;

  &.notification-wallet-success {
    border-radius: 50px !important;
    max-width: 225px;
    height: 27px;

    .success-box-text {
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
    }
  }

  &.notification-wallet-warning {
    max-width: 225px;
    height: 27px;
    border-radius: 50px;
    border: solid 1px #e9a81b;
    background-color: rgba(215, 147, 1, 0.17);

    .success-box-text  {
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: normal;
      text-align: center;
      color: $wallet-notification-warning;
    }
  }

  &.notification-wallet-warning {
    max-width: 225px;
    height: 27px;
    border-radius: 50px;
    border: solid 1px #e9a81b;
    background-color: rgba(215, 147, 1, 0.17);

    .success-box-text  {
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: normal;
      text-align: center;
      color: $wallet-notification-warning;
    }
  }
}

.success-box-text {
  font-family: Comfortaa, serif;
  font-size: 12px;
  line-height: 1.25;
  text-align: center;
  color: $green-increase;
}