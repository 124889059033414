@import '../../../../styles/styles';

.monetaryBase-error-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 24px 24px 24px 32px;
    max-height: 530px
}

.monetaryBase-error-modal .wrapper-modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 15px;
}

.monetaryBase-error-modal .icon-info {
    height: 45px;
    width: 45px, ;
    padding: 10px;
    font-weight: 'bold';
    color: $decline-color-light;
    border: 2px solid $decline-color-light;
    border-radius: 50%;
}

.monetaryBase-error-modal .subtitle {
    font-family: "OpenSans", sans-serif;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.7;
    letter-spacing: normal;
    color: #011415;
    text-align: center;
}