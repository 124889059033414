@import "../../../styles/main.scss";

.emptyTable-row {
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $gray;
    
}
