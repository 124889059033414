@import "../../../styles/main.scss";

.os-transactions-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-right: 81px;
  padding-left: 81px;
  background-color: $background-color;

  .header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: auto;
    width: 100%;
    margin-top: 45px;
    margin-bottom: 23px;
    align-self: center;
    background-color: transparent;

    .title {
      margin-right: 43px;
      font-size: 26px;
      font-family: $font-Comfortaa;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.75px;
      color: $font-main-color;
    }

    .select-multiple-container{
      margin-left: 20px;
      margin-right: 20px;
    }

    .buttons {
      display: flex;
      .button {
        margin-left: 14px;
      }
    }
  }

  .transaction-table-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .transaction-table-footer {
      display: flex;
      align-items: center;

      .button {
        width: 158px;
        height: 33px;
        background-color: $nexus-secondary-green;
        border: $nexus-secondary-green;

        i {
          font-size: 25px;
          margin-right: 10px;
        }
      }

      .paged-drawer {
        margin-left: 30px;
      }
    }

    .marginX {
      margin: 1rem 3rem;
    }

    .header-with-checkbox {
      display: inline-flex;
      align-items: center;
      input {
        margin-left: 14px;
      }
    }

    // to avoid underline on tooltip
    a {
      text-decoration: none;
    }

    .wrap {
      overflow-wrap: anywhere;
    }

    .transaction-tooltip {
      opacity: 1;
      border-radius: 6px;
      box-shadow: 0 2px 11px 0 rgba(94, 94, 94, 0.1);
      padding: 15px 10px 15px 10px;

      font-family: $font-OpenSans;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $font-main-color;
      text-underline: none!important;

      &.paragraph {
        padding: 30px 20px 30px 20px;
      }
    }

    .status-container {
      display: flex;
      align-items: center;
      flex: 1;

      .status {
        &.pending {
          color: #d0a754;
        }
        &.processingPayment {
          color: #d0a754;
        }
        &.rejected {
          color: #c75959;
        }
        &.validated {
          color: $nexus-primary-green;
        }

        &.validationerror {
          color: #c75959;
        }

        &.validationError {
          color: #c75959;
        }

        &.errorsap {
          color: #c75959;
        }

        &.paid {
          color: #848484;
        }
      }

      .error-container {
        display: flex;
        flex: 1;
        justify-content: space-between;
        align-items: center;

        .status-error-indicator {
          height: 8px;
          width: 8px;
          background-color: #c75959;
          border-radius: 30px;
        }
      }
    }
  }

  .modal {
    .header {
      height: auto;
      width: 100%;
      flex-direction: column;

      i {
        font-size: 70px;
        -webkit-text-stroke: unset;
        margin-bottom: 16px;

        &.red {
          color: $icon-error;
        }
        &.green {
          color: #53bfa9;
        }
      }

      .title {
        font-weight: 600;
        color: $title-color;
      }
    }

    .separator-line {
      display: none!important;
    }
  }
}


.action-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 5em;
  height: 100%;
  gap: 5px;
}

.action-container i {
  font-weight: bold;
  color: #000B;
  user-select: none;
  &:hover {color: $nexus-primary-green;}
  &:active {color: $nexus-secondary-green;}
}
.action-pipe {
  height: 70%;
  width: 1px;
  background-color: #e5e5e5;
  display: inline-block;
  user-select: none;
}

.cycle-container {
  z-index: 999!important;
}

.cycle-dropdown {
  background-color: #FFF;
  width: max-content;
  min-width: 50em;
}


.ExpandableLabel{
  position: relative;


  > .ExpandablePopup {
    position: absolute;
    top: 2em; right: 1rem;

    thead {
      z-index: 999 !important;
    }
  }
}
