@import "../../../styles/main.scss";

.transactions-table-row {
  margin-top: 0.5rem;
  &.auditPending {
    color: $icon-warning !important;
    font-size: 16px;
  }
  &.auditRejected {
    color: $icon-error !important;
    font-size: 16px;
  }
  &.auditApproved {
    color: $nexus-primary-green !important;
    font-size: 16px;
  }
  &.details {
    color: #8d8d8d !important;
    font-size: 16px;
    text-decoration: underline;
  }
}

.info-modal {
  background: $gray;
  opacity: 1 !important;
  box-shadow: 0px 30px 15px 0px rgba(40, 40, 40, 0.1);
  display: flex;
  flex-direction: column;
  width: 30rem;
  height: auto;

  .list {
    li {
      padding-top: 0rem;
      :first-child {
        text-decoration: underline;
      }
    }

    li::marker {
      font-size: 3rem;
    }

    h5 {
      margin-top: 0;
      text-align: start;
      width: 100%;
      text-align: left;
    }
    :first-child::marker {
      color: $icon-warning; /* or whatever color you prefer */
    }
    &.os-transactions{
      :first-child::marker {
        color: #c75959; /* or whatever color you prefer */
      }
      :nth-child(2)::marker {
        color: #c75959;
      }
      :nth-child(3)::marker {
        color: #848484;
      }
      :nth-child(4)::marker {
        color: #848484;
      }
      :nth-child(5)::marker {
        color: #d0a754;
      }
      :nth-child(6)::marker {
        color: #d0a754;
      }
      :nth-child(7)::marker {
        color: #c75959;
      }
      :nth-child(8)::marker {
        color: $nexus-primary-green;
      }
    }
    &.required{
      :nth-child(2)::marker {
        color: $nexus-primary-green;
      }
    }
    &.approved{
      :nth-child(2)::marker {
        color: $nexus-primary-green;
      }
    }
    :nth-child(3)::marker {
      color: $icon-error;
    }
    :nth-child(4)::marker {
      color: #54A0D0;
    }
    :nth-child(5)::marker {
      color: $icon-error;
    }
    .description {
      font-weight: 800 !important;
      color: rgba(90, 90, 90, 0.8);
      font-size: small;
      text-align: start;
    }
  }
}
.download-btn {
  width: 158px;
  height: 33px;
  background-color: $nexus-secondary-green!important;
  border: $nexus-secondary-green!important;

  & i {
    font-size: 25px;
    margin-right: 10px;
  }
}
.red-dot-number {
  color: #c75959;
  font-size: 20px;
  margin-right: 10px;
}

.numbers {
  display: flex;
  justify-content: flex-end;
}
