@import "../../../styles/main.scss";

.registrar-account-activation-screen {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(to bottom, $nexus-primary-green, $nexus-secondary-green);
  padding-right: 20px;
  padding-left: 20px;

  .card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 657px;
    min-height: 200px;
    border-radius: 13px;
    background-color: #ffffff;
    margin-top: 38px;

    .activation-result {
      display: flex;
      flex-direction: column;
      align-items: center;

      i {
        font-size: 33px;
      }

      &.success {
        i {
          color: $icon-success;
        }
      }

      &.error {
        i {
          color: $icon-error;
        }
      }

      .title {
        font-family: $font-Comfortaa;
        font-size: 26px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.85;
        letter-spacing: -0.63px;
        text-align: center;
        color: #323133;
        margin-top: 35px;
        padding: 0 18px 18px 18px;
      }
    }
  }

  .back-to-top {
    font-family: $font-Comfortaa;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: normal;
    color: #ffffff;
    text-decoration: underline;
    margin-top: 40px;

    &:hover {
      cursor: pointer;
    }
  }
}