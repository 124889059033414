@import '../../../../styles/styles.scss';

.body-min-burn {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  .wrapper-child {
    flex: 1;

    .mint-burn-title {
      font-family: $font-Comfortaa ;
      font-weight: bold;
      color: $title-color;
      margin-bottom: 5px;
    }
  }
  .text-area-wrapper{
    height: 100px;
  }
  .text-area-resize{
    height: 100%;
    border: $space-gray 1px solid;
    border-radius: 5px;
    color: $text-black;
    font-family: $font-Comfortaa;
    font-size: 26px;
    letter-spacing: 2px;
  }
}