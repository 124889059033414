@import "../../../styles/main.scss";

.multiple-download-button-container {
  display: flex;

  .multiple-download-button {
    position: relative;
    display: flex;
    align-items: center;
    padding: 10px 5px 10px 5px;
    //border: solid 1px #dfe2e6;
    border-radius: 4px;
    background-color: $nexus-primary-green;

    i {
      &.big {
        font-size: 25px;
        margin-left: 0;
      }
      margin-left: 15px;
      color: white;
    }

    &.rounded {
      height: 38px;
      border-radius: 30px;
      padding: 0 15px 0 15px;
    }
  }

  .multiple-download-button:hover {
    cursor: pointer;
  }

  .multiple-download-button-options-container {
    position: absolute;
    z-index: 999;
    display: none;
    padding: 27px 20px 27px 20px;
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: 0 2px 14px 0 rgba(118, 118, 118, 0.5);
    margin-top: 44px;
    right: 80px;

    &.show {
      display: flex!important;
      flex-direction: column;
    }

    .download-option {
      font-family: $font-Comfortaa;
      font-size: 16px;
      font-weight: 600;
      color: #021516;
      margin: 5px 0 5px 0;
    }

    .download-option:hover {
      cursor: pointer;
    }
  }
}