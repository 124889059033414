@import '../../../styles/main.scss';

.i-container {
    width: 100%;
    padding: 3em;
}

//  ----- TABLE -----

table.DefTable {
    width: 100%;
    text-align: center;
    box-shadow: 0 0 10px 0 #0005;
    border-radius: 5px;
    > thead > tr > th {
        background-color: #f4f4f4;
        text-align: center;
        &:first-child {
            border-top-left-radius: 5px;
        }
        &:last-child {
            border-top-right-radius: 5px;
        }
    }

    > thead > tr > th,
    > tbody > tr > td {
        border-right: 1px solid #ccc;
        padding: 0.2em 1em;
        &:last-child {
            border-right: none;
        }
    }

    > tbody > tr:nth-child(even) {
        background-color: #f4f4f4;
    }
    > tbody > tr:last-child {
        > td:first-child {
            border-bottom-left-radius: 5px;
        }
        > td:last-child {
            border-bottom-right-radius: 5px;
        }
    }
}

.react-datepicker {
    box-shadow: 0 2px 20px 5px rgba(200, 200, 200, 0.7);
    border: 0;
    font-family: 'Comfortaa';
    border-radius: 1em;
    .react-datepicker__triangle,
    .react-datepicker__triangle::before {
        border: 0;
    }
    .react-datepicker__navigation {
        padding: revert;
        width: 2.5em;
        height: 2.5em;
        border-radius: 2em;
        background-color: #fff;
        box-shadow: 0 0 2px 0 #0005;
        &:hover {
            box-shadow: 0 0 3px 0 #0008;
        }
    }
    .react-datepicker__navigation--next {
        right: -10px;
        border-left-color: #fff0;
        top: 10em;

        &::before {
            content: ' ';
            position: absolute;
            width: 1em;
            height: 1em;
            top: 1.5px;
            left: 0;
            background-color: #12b89c;
            transform: rotate(45deg);
        }
        &::after {
            content: ' ';
            position: absolute;
            width: 1em;
            height: 1em;
            top: 1.5px;
            left: -2px;
            background-color: #fff;
            transform: rotate(45deg);
        }
    }
    .react-datepicker__navigation--previous {
        left: -10px;
        border-right-color: #fff0;
        top: 10em;
        &::before {
            content: ' ';
            position: absolute;
            width: 1em;
            height: 1em;
            top: 1.5px;
            left: 3.5px;
            background-color: #12b89c;
            transform: rotate(45deg);
        }
        &::after {
            content: ' ';
            position: absolute;
            width: 1em;
            height: 1em;
            top: 1.5px;
            left: 5.5px;
            background-color: #fff;
            transform: rotate(45deg);
        }
    }
    .react-datepicker__month-container {
        float: left;
        padding: 0 2em 0 2em;
        .react-datepicker__header {
            text-align: center;
            background-color: #fff;
            border-bottom: 0;
            border-top-left-radius: 0.3rem;
            padding-top: 8px;
            position: relative;
            .react-datepicker__current-month {
                display: flex;
                padding-left: 10px;
            }
            .react-datepicker__day-names .react-datepicker__day-name {
                color: #dfdfdf;
            }
        }
        .react-datepicker__day--keyboard-selected,
        .react-datepicker__day--selected,
        .react-datepicker__day:hover {
            border-radius: 1rem;
            background-color: #12b89c;
            color: #fff;
        }
    }
}

.flex-row {
    display: flex;
    align-items: center;
    height: 47px;
    .unit {
        padding: 1em;
        font-size: 13pt;
        font-weight: 500;
    }
}

.expandable-link .expandable-container table tbody tr:hover {
    background-color: #eef;
}

.primaryText {
    color: $nexus-primary-green !important;
}
._disabled {
    color: #bbb !important;
}

table.addcontractstable {
    tr.disabled.repeated {
        td {
            color: #b338 !important;
        }
    }
}
