@import '../../../styles/styles.scss';

.add-new-screen-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 0.5% 10% 1% 10%;
}


.add-new-card {
  margin-top: 2vh;
  width: 87%;
  height: 90%;



  .add-new-card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 2% 0 5% 0;
    height: 92%;
  }

  .add-new-input {
    width: 45%;
    height: 5vh;
    max-height: 90px;
    min-height: 30px;
    margin-bottom: 2vh;
    border: 0 !important;
    text-overflow: ellipsis;
  }

  .add-new-input-label {
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 45%;
    margin-bottom: 0;
  }

  .add-new-card-buttons-container {
    margin-top:6vh ;
    width: 100%;
    min-width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;

  }

  .add-new-card-icon-container{
    width: 8%;
    aspect-ratio: 1/1;
    margin-bottom: 5vh;
  }
}

