@import "../../../styles/styles.scss";

.recover-password-screen {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(to bottom, $nexus-primary-green, $nexus-secondary-green);

  .card {
    width: 657px;
    height: auto;
    border-radius: 13px;
    background-color: #ffffff;
    margin-top: 38px;

    .form, .recover-instructions {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .title {
      font-family: $font-Comfortaa;
      font-size: 26px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.85;
      letter-spacing: -0.63px;
      text-align: center;
      color: #323133;
    }

    .form {
      .title {
        margin-top: 61px;
      }

      .input-container {
        width: -moz-calc(100% - 104px);
        width: -webkit-calc(100% - 104px);
        width: calc(100% - 104px);
        margin-right: 52px;
        margin-left: 52px;
        margin-top: 44px;
      }

      @media (max-width: 767px) {
        .input-container {
          width: -moz-calc(100% - 52px);
          width: -webkit-calc(100% - 52px);
          width: calc(100% - 52px);
          margin-right: 26px;
          margin-left: 26px;
          margin-top: 44px;
        }
      }

      .button {
        width: 241px;
        height: 50px;
        border-radius: 20px;
        box-shadow: 0 10px 20px 0 #d2efe9;
        background-image: linear-gradient(to bottom, $nexus-primary-green, $nexus-secondary-green);
        margin-top: 44px;
        margin-bottom: 41px;

        .text {
          font-family: $font-Comfortaa;
          font-size: 16px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #ffffff;
        }
      }
      // todo maybe the error container can be abstract to the main.scss
      .error-container {
        min-height: 53px;
        height: auto;
        padding-right: 5px;
        width: -moz-calc(100% - 216px);
        width: -webkit-calc(100% - 216px);
        width: calc(100% - 216px);
        margin-top: 22px;
        margin-right: 108px;
        margin-left: 108px;
        border-radius: 5px;
        display: flex!important;
        justify-content: center;
        align-items: center;

        &.show {
          border: solid 1px $error-main;
          background-color: $error-secondary;

          i, .error-msj {
            display: block;
          }
        }

        i {
          display: none;
          color: $icon-error;
          font-size: 20px;
          margin-right: 21px;
          margin-left: 21px
        }

        .error-msj {
          display: none;
          font-family: $font-Comfortaa;
          font-size: 16px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.38;
          letter-spacing: -0.39px;
          //text-align: center;
          color: #323133;
        }
      }
      @media (max-width: 767px) {
        .error-container {
          width: -moz-calc(100% - 108px);
          width: -webkit-calc(100% - 108px);
          width: calc(100% - 108px);
          margin-right: 54px;
          margin-left: 54px;
          margin-top: 44px;
          padding-top: 8px;
          padding-bottom: 8px;
        }
      }
    }

    .recover-instructions {
      .icon-check {
        font-size: 33px;
        margin-top: 35px;
        color: #2aab9a;
      }

      .title {
        margin-top: 35px;
      }

      .paragraph-container {
        margin: 18px 78px 58px 79px;

        .paragraph {
          font-family: $font-Comfortaa;
          font-size: 18px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.22;
          letter-spacing: -0.43px;
          text-align: center;
          color: #323133;

          &.last {
            margin-top: 15px;
          }
        }
      }

    }
  }
  @media (max-width: 767px) {
    .card {
      width: 80%;
    }
    .input-container {
      width: -moz-calc(100% - 52px);
      width: -webkit-calc(100% - 52px);
      width: calc(100% - 52px);
      margin-right: 26px;
      margin-left: 26px;
      margin-top: 44px;
    }
  }

  .back-to-top {
    font-family: $font-Comfortaa;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: normal;
    color: #ffffff;
    text-decoration: underline;
    margin-top: 40px;

    &:hover {
      cursor: pointer;
    }
  }
}