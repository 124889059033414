@import '../../../styles/styles.scss';

.listeditor-wrapper{

    position: relative;
    width: 100%;

    .listeditor-main{
        height: 47px;
        border-radius: 7px;
        border: 1px solid #dfe2e6;

        display: flex;
        align-items: center;
        justify-content: center;

        font-size: 12pt;
        font-weight: 500;
        
        &.error {
            border-color: $icon-error;
        }
    }

    .listeditor-popup{
        position: absolute;
        left: 0;right: 0; margin: auto;
        min-width: max-content;
        width: 100%;
        z-index: 300;
        
        border-radius: 7px;
        border: 1px solid #dfe2e6;
        padding: 1em;
        background-color: #FFF;

        table{
            width: 100%;
            thead > tr > th,
            tbody > tr > td{
                padding: .2em 1em;
            }
        }
    }

}
.listeditor-wrapper:focus{
    outline: none;
}