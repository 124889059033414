@import "../../../styles/styles";

.underline-title {
  border-radius: 2px;
  margin: 0;
  position: relative;
  bottom: -3px;

  &.light-green {
    border: 2px solid $continue-color-light;
  }

  &.coordination {
    border: 2px solid $coordination-color-dark;
  }

  &.sample {
    border: 2px solid $nexus-primary-green;
  }

  &.courier {
    border: 2px solid $courier-color-dark;
  }

  &.ongoing {
    border: 2px solid $coordination-color-dark;
  }

  &.report {
    border: 2px solid $report-color-dark;
  }

  &.fixed {
    width: 130px;
  }
}

.wallet-title {
  font-family: $font-Comfortaa;
  font-size: calc(100% + 0.1em);
  font-weight: bold;
  color: $title-color;
}

.wrapper-title-linkAccess {
  margin-top: 7%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .body-titleBar {
    width: auto;
    .container-line-titleBar {
      width: 100%;
    }
  }

  .body-linkAccess {
    display: flex;
    align-items: center;
   justify-content: flex-end;
    width: 20%;
  }
}