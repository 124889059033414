//fonts
$text-font: 'Roboto', serif;
$header-font: 'CoconPro', sans-serif;

// Nexus Fonts
$font-Comfortaa: 'Comfortaa';
$font-CoconPro: 'CoconPro';
$font-OpenSans: 'OpenSans', sans-serif;

// colors
// - defaults
$light: #afb3ba;
$disabled: #d4d8dd;
$gray: #eaeaea;
$light-gray-fill: #f3f3f3;
// - background
$background: #f0f0f0;
$light-green-background: #ddeeea;

// - text
$light: #afb3ba;
$gray: #eaeaea;
$light-text: $light;
$title-color: #011415;
$space-gray: #979797;
$text-black: #1a1a1a;

// Nexus colors
$nexus-primary-green: #2cb095;
$nexus-secondary-green: #008e99;
$nexus-gradient-green: linear-gradient(to bottom, rgb(44, 176, 149), rgb(0, 142, 153));
$nexus-background-green: #ffffff;
$light-gray-fill: #dfe2e6;
$icon-gray: #a4a8ad;
$error-main: #da8d8d;
$error-secondary: #f8d9d9;
$icon-error: #c95857;
$icon-success: #53bfa9;
$icon-warning: #D0A754;
$font-main-color: #344344;
$background-color: #f1f1f1;

$engave-color-light: #e9a81b;
$engave-color-transparent: rgba(233, 168, 27, 0.2);
$engave-color-dark: #d79301;
$decline-color-light: #c33838;
$decline-color-dark: #ac3030;
$decline-color-gradient: linear-gradient(to bottom, rgb(195, 56, 56), rgb(172, 48, 48) 100%);
$green-increase: #057a83;
$continue-color-light: #2cb095;
$continue-color-dark: #008e99;

$prospect-color-light: rgba(44, 176, 149, 0.23);
$prospect-color-dark: #0a8fc8;
$coordination-color-light: rgba(10, 143, 200, 0.24);
$coordination-color-dark: #0a8fc8;
$sample-color-light: rgba(44, 176, 149, 0.15);
$courier-color-light: rgba(178, 163, 23, 0.22);
$courier-color-dark: #b2a317;
$ongoing-color-light: rgba(5, 122, 131, 0.23);
$report-color-light: rgba(210, 105, 8, 0.22);
$report-color-dark: #d26908;

$modal-color-white: #fff;
$white: #FFFFFF;

$wallet-color-white: #f7f7f7;
$wallet-color-back: #292d32;
$wallet-color-light: rgba(5, 122, 131, 0.18);
$wallet-shadow: rgba(216, 216, 216, 0.82);

$wallet-notification-warning: #926402;