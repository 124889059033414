@import "../../../styles/main.scss";

.profile-card {
  display: flex;
  flex-direction: column;
  border-radius: 7px;
  box-shadow: 0 2px 4px 0 rgba(228, 228, 228, 0.5);
  background-color: #ffffff;
  margin-right: 32px;
  max-width: 292px;

  .profile-card-header {
    display: flex;
    flex-direction: column;
    width: 100%;
    // todo check if is better to utilize flex instead of pixels
    height: 151px;
    border-radius: 0;
    // todo review the image, because is not occupy the whole space
    background: url("../../../assets/images/profile-green-bar-big.png") ;
    background-size: cover;
    padding: 51px 0 32px 32px;

    img {
      width: 137px;
      height: 27px;
    }

    .os-name {
      font-family: $font-Comfortaa;
      font-size: 20px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
      margin-top: 17px;
    }
  }

  .profile-card-body {
    display: flex;
    flex-direction: column;
    padding: 28px 52px 32px 36px;

    .user-name {
      font-family: $font-OpenSans;
      font-size: 18px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $font-main-color;
      margin-bottom: 5px;
    }

    .icon-and-text-row {
      display: flex;
      margin-top: 15px;

      i {
        font-size: 16px;
        color: $icon-gray;
      }

      .text-value {
        font-family: $font-OpenSans;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $font-main-color;
        margin-left: 14px;
      }
    }
  }

  hr {
    width: 100%;
    border: solid 1px #f3f3f3;
    margin: 0;
  }

  .profile-card-footer {
    display: flex;
    justify-content: center;
    padding: 21px 33px 29px 33px;

    i {
      font-size: 18px;
      color: #656565;
      margin-right: 9px;
    }

    span {
      font-family: $font-Comfortaa;
      font-size: 15px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-decoration: underline;
      color: #5a5a5a;
    }

    span:hover {
      cursor: pointer;
    }
  }
}