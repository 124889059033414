@import "../../../styles/main.scss";

.transactions-table-row {
  margin-top: 0.5rem;
  &.auditPending {
    color: $icon-warning !important;
    font-size: 16px;
  }
  &.auditRejected {
    color: $icon-error !important;
    font-size: 16px;
  }
  &.cleanedEvidence {
    color: $icon-error !important;
    font-size: 16px;
  }
  &.auditApproved {
    color: $nexus-primary-green !important;
    font-size: 16px;
  }
  &.auditConsumptionRejected {
    color: #54A0D0 !important;
    font-size: 16px;
  }
  &.endTreatmentProgression {
    color: $error-main !important;
    font-size: 16px;
  }
  &.endSuccessfulTreatment{
    color: $icon-success !important;
    font-size: 16px;
  }
  &.noAuditor {
    font-size: 16px;
  }
  &.details {
    color: #8d8d8d !important;
    font-size: 16px;
    text-decoration: underline;
  }
}

.info-modal-os {
  background: $gray;
  opacity: 1 !important;
  box-shadow: 0px 30px 15px 0px rgba(40, 40, 40, 0.1);
  display: flex;
  flex-direction: column;
  width: 30rem;
  height: auto;
  .list {
    li {
      padding-top: 0rem;
      :first-child {
        text-decoration: underline;
      }
    }

    li::marker {
      font-size: 3rem;
    }

    h5 {
      margin-top: 0;
      text-align: start;
      width: 100%;
      text-align: left;
    }

    :first-child::marker {
      color: $icon-warning; /* or whatever color you prefer */
    }
    :nth-child(2)::marker {
      color: $nexus-primary-green;
    }
    :nth-child(3)::marker {
      color: $icon-error;
    }
    :nth-child(4)::marker {
      color: #54A0D0;
    }
    :nth-child(5)::marker {
      color: $icon-error;
    }
    .description {
      font-weight: 800 !important;
      color: rgba(90, 90, 90, 0.8);
      font-size: small;
      text-align: start;

    }
  }
}
