@import "../../../styles/main.scss";

.title-card {
  margin-left: 37px;

  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer;
  width: 100%;
  overflow: hidden;

  .item-card-row {
    padding: 0 0.4em 0 0.4em;

    &.title {
      font-family: Comfortaa;
      font-size: 14px;
      font-weight: 600;
      text-align: left;
      color: #011415;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.icon-card-action {
  display: flex;

  .item-icon {
    padding-left: 2em;

    &:hover {
      cursor: pointer;
    }
  }
}