@import '../../../styles/styles.scss';

.button {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  width: 200px;
  height: 48px;
  font-weight: 500;
  font-size: 15px;
  cursor: pointer;
  padding: 2px 7px;
  border: none;

  &:active {
    opacity: 0.5 !important;
  }

  // The button loader, placed on the center of the button
  svg {
    position: absolute;
    height: 100%;
    width: 50%;
    top: 0;
    left: 25%;
    fill: $nexus-primary-green
  }

  .button-body {
    display: flex;
    justify-content: center;
    align-items: center;

    .button-text {
      font-family: $font-Comfortaa;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
    }
  }

  &:focus {
    outline: none;
    border: none;
  }

  /* Styles */
  &.primary {
    background-color: $nexus-primary-green;
    border: solid 2px $nexus-primary-green;
    color: white;

    &.with-gradient {
      // height: auto;
      box-shadow: 0 10px 20px 0 #d2efe9;
      background-image: linear-gradient(to bottom, #2cb095, #008e99);
      border: none;

      &.big {
        height: 41px !important;
        width: 100%;
      }
      &.wallet {
        height: 48px;
        width: 100%;
      }
    }

    &.no-shadow {
      box-shadow: 0 0 0 0 #d2efe9;
      width: 124px !important;
      height: 39px !important;
    }
  }

  &.secondary {
    height: unset;
    padding: 6px 30px 9px 30px;
    background-color: #e8e8e8;
    border: none;

    .button-body {
      .text {
        color: #082e31;
        font-size: 14px;
        font-weight: 600;
      }

      i {
        font-size: 25px;
        color: $nexus-secondary-green;
        margin-right: 10px;
      }
    }
  }

  &.white {
    background-color: #ffffff;

    .button-body {
      .text {
        color: #037c86;
        font-size: 14px;
        font-weight: 600;
      }
    }
  }

  &.bold {
    .button-text {
      color: #037c86;
      font-weight: bold;
    }
  }

  &.error {
    background-color: $icon-error;
    border: solid 2px $icon-error;
    color: white;
  }

  &.continue {
    background-image: linear-gradient(to bottom, $continue-color-light, $continue-color-dark);
    color: $background-color;
  }

  &.decline {
    background-image: linear-gradient(to bottom, $decline-color-light, $decline-color-dark);
    color: $background-color;
  }

  &.engrave {
    background-image: linear-gradient(to bottom, $engave-color-light, $engave-color-dark);
    color: $background-color;
  }

  &.hollow {
    background-color: transparent;
    border: solid 2px $nexus-primary-green;
    border-radius: 5em;
    height: 3em;

    .button-text {
      color: $nexus-secondary-green;
      font-size: 14px;
      font-weight: 900;
    }

    &.wallet {
      width: 100%;
      border: solid 1px #057a83;

      .button-text {
        color: $green-increase;
      }
    }

    &.error {
      background-color: transparent;
      border: solid 2px $icon-error;
      border-radius: 5em;
      height: 3em;

      .button-text {
        color: $icon-error;
      }
    }
  }

  &.third {
    background-color: $nexus-secondary-green;
    border: solid 2px $nexus-secondary-green;
    color: white;
  }

  &.disabled {
    background-color: $disabled;
    border: 1px solid $disabled;
    color: white;
    background-image: none !important;
    box-shadow: none !important;
  }

  /* Sizes */
  &.small {
    height: 40px;
    width: 149px;

    .text {
      font-size: 14px !important;
    }
    &.margin-right{
      margin-right: calc(5px + 2%);
    }
  }

  &.tiny {
    height: 40px;
    width: 125px;

    .button-text {
      font-size: 14px !important;
      font-weight: 600;
    }
  }

  &.small-wide {
    height: 40px;
    width: 170px;

    .text {
      font-size: 14px !important;
    }
  }

  &.icon-size {
    height: 40px;
    width: 40px;
  }

  &.wide {
    width: 350px;
    height: 50px;

    .text {
      font-size: 18px !important;

    }
  }
}