@import '../../../styles/main.scss';

.profile-tooltip {
    display: flex;
    position: absolute;
    width: 333px;
    border-radius: 4px;
    box-shadow: 0 2px 14px 0 rgba(118, 118, 118, 0.5);
    background-color: #ffffff;
    z-index: 9999;
    flex-direction: column;
    top: 101px; // due to header height
    right: 50px; // margin of the arrow icon on header

    .bar {
        width: 100%;
        height: 37px;
    }

    // not used, using image instead
    .colored-header {
        height: 37px;
        border-radius: 4px 4px 0 0;
        box-shadow: 0 2px 4px 0 rgba(228, 228, 228, 0.5);
        background-image: linear-gradient(
            102deg,
            $nexus-primary-green 51%,
            $nexus-secondary-green 99%
        );
    }

    .profile-tooltip-body {
        display: flex;
        flex-direction: column;
        padding: 16px 33px 34px 29px;

        .welcome-title {
            font-family: $font-Comfortaa;
            font-size: 18px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $font-main-color;
        }

        .os-subtitle {
            font-family: $font-OpenSans;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #acabab;
            margin-top: 5px;
        }

        hr {
            width: 100%;
            border: solid 1px #e5e5e5;
            margin-top: 19px;
        }

        .icon-and-text-container {
            display: flex;
            padding-left: 13px;
            margin-top: 19px;

            &.clickable {
                &:hover {
                    opacity: 0.8;
                }
                &:active {
                    opacity: 0.5;
                }
            }

            i {
                font-size: 19px;
                font-weight: normal;
                color: #abaaaa;
            }

            .text-data {
                font-family: $font-OpenSans;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: $font-main-color;
                margin-left: 9px;

                &.underline {
                    text-decoration: underline;
                }

                &.margin-left {
                    margin-left: 15px;
                }
            }
        }

        .active-key-box {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 40px;
            border-radius: 5px;
            border: solid 1px #58beab;
            background-color: #d1ece6;
            margin-top: 20px;
            margin-bottom: 5px;

            i {
                -webkit-text-stroke: 1px #055f62;
                font-size: 20px;
                color: #0e9997;
                margin-right: 10px;
            }

            span {
                font-family: $font-Comfortaa;
                font-size: 13px;
                font-weight: bold;
                color: #055f62;
            }
        }

        .private-key-button {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 40px;
            border-radius: 7px;
            background-color: white;
            border: solid 1px #22a996;
            margin-top: 20px;
            margin-bottom: 5px;

            &:hover {
                cursor: pointer;
            }

            i {
                font-size: 22px;
                color: #079398;
            }

            .text {
                font-family: $font-Comfortaa;
                font-size: 13px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #079398;
                text-decoration: underline;
                margin-left: 7px;
            }

            &.fill {
                margin-right: 12px;
                margin-left: 0;
                background-image: linear-gradient(to bottom, #28ad95, #059299);

                i,
                .text {
                    color: white !important;
                }
            }
        }
        > button {
            display: flex;
            border: 1px solid #008e99;
            margin-top: 16px;
        }
    }
}
