@import '../../../styles/main.scss';

.date-range-picker {
    display: flex;
    flex: 1;
    height: 38px !important;
    width: 100%;
    margin-bottom: 0;
    align-items: center;

    > label {
        margin-top: 4px;
        margin-right: 8px;
    }
}

.react-datepicker-wrapper {
    border: solid 1px $light-gray-fill !important;
    border-radius: 20px;
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
    height: 100%;
    border-radius: 20px;
    border: none;
    background-color: #ffffff !important;
    margin-right: 2%;
    font-size: 12px;
    padding: 4px 8px;
    font-family: $font-Comfortaa;
    font-weight: bold;
    outline: none;
}

.react-datepicker {
    zoom: 150%;
}

i.clear-dates-selection-icon {
    font-size: 25px;
    color: #131415;
}

i:hover {
    cursor: pointer;
}

button.clear-dates-selection-button {
    height: 100%;
    padding: 0;
    border: none;
    background: none;
    outline: none;
}
