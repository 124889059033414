@import "../../../styles/main.scss";
@import '../../../styles/styles.scss';

.search-bar {
  display: flex;
  flex: 1;
  margin-right: 2%;

  .input-container {
    background-color: #ffffff !important;
    height: 38px !important;
    width: 100%;
    margin-bottom: 0;
    border-radius: 50px;
    .input {
      font-size: 12px;
    }
  }

  i {
    font-size: 18px;
  }
}

.search-bar-wallet {
  .input-container {
    height: 39px !important;

    margin-bottom: 0;

    border-radius: 50px;
    box-shadow: 0 4px 30px 0 $wallet-shadow;
    border: solid 1px rgba(5, 122, 131, 0.18) !important;
    background-color: #f7f7f7 !important;
  

    .input {
      border-radius: 50px;

      font-family: Comfortaa;
      font-size: 14px;
      font-weight: 600;
      text-align: left;
      color: rgba(1, 20, 21, 0.76);
      background-color: #f7f7f7;
    }
    ::placeholder{
      color: rgba(1, 20, 21, 0.76);
    }
    
  }
  .icon-color {
    color: $green-increase;
  }
}