@import "../../../styles/styles.scss";

.login-screen {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .left-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    height: 100%;
    background-color: white;

    .logo {
      width: 100%;

      img {
        width: 117px;
        height: 61px;
        margin-top: 47px;
        margin-left: 48px;
      }
    }

    .form-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 441px;
      margin-right: 30px;
      margin-left: 30px;
      flex: 1;

      .form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        width: 100%;

        .error-container {
          height: 53px;
          width: 100%;
          margin-top: 22px;
          border-radius: 5px;
          display: flex!important;
          justify-content: center;
          align-items: center;

          &.show {
            border: solid 1px $error-main;
            background-color: $error-secondary;

            i, .error-msj {
              display: block;
            }
          }

          i {
            display: none;
            color: $icon-error;
            font-size: 20px;
            margin-right: 21px;
          }

          .error-msj {
            display: none;
            font-family: $font-Comfortaa;
            font-size: 16px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.38;
            letter-spacing: -0.39px;
            text-align: center;
            color: #323133;
          }
        }

        .title {
          opacity: 0.8;
          font-family: $font-CoconPro;
          font-size: 30px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $title-color;
          width: 100%;
          margin-bottom: 16px;
          margin-top: 44px;
        }

        .login-field {
          margin-top: 32px;
        }

      }
    }

    .power-by-container {
      width: 441px;
      height: 61px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-top: 46px;

      .power-by-text {
        font-family: $font-Comfortaa;
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.07;
        letter-spacing: normal;
        color: $font-main-color;
      }
    }

    @media (max-width: 500px) {
      .form-container {
        margin-left: 15px;
        margin-right: 15px;
        width: 290px;
      }
      .power-by-container {
        width: 290px;
      }
    }
  }

  .right-container {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-image: url("../../../assets/images/Group.png");
    background-size: cover;
  }

  // Hides the right container when width is less than 992px
  @media (max-width: 991px) {
    .right-container {
      display: none;
    }
  }

  .forgot-password {
    text-decoration: underline;
    font-family: $font-Comfortaa;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: normal;
    color: $font-main-color;
    margin-bottom: 41px;

    &:hover {
      cursor: pointer;
    }
  }

  .buttons {
    display: flex;
    justify-content: space-between;
  }

  .button {
    width: 202px;
    height: 50px;
    border-radius: 20px;
    box-shadow: 0 10px 20px 0 #d2efe9;
    background-image: linear-gradient(to bottom, $nexus-primary-green, $nexus-secondary-green);
  }
}
