.access-table-screen-container{
  width:100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.users-table-card{
  width: 85%;
  margin-top: 20px;
  background-color: #FFFFFF;
  border-radius: 21px;
  padding: 80px 45px 45px 45px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.text-ellipsis{
  text-overflow: ellipsis;
}
.users-table{
  width: 100%
}
.action-icons-container{
    display: flex;
    justify-content: space-between;
  cursor: pointer;
  &.disabled{
    cursor: not-allowed;
  }
}