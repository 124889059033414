@import "../../../../styles/styles.scss";

.wallet-card {
  width: 100%;
  height: 100%;
  
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;

  border-radius: 7px;
  box-shadow: 0 4px 30px 0 $wallet-shadow;
  border: solid 1px $wallet-color-light;
  background-color: $wallet-color-white;

  &.transaction {
    height: 100%;
    padding: 0 8.5% 0 5%;
    box-shadow: 0 1px 10px 0 $wallet-shadow;
  }
  &.no-transaction{
    height: 30%;
    padding: 0 8.5% 0 5%;
    box-shadow: 0 1px 10px 0 $wallet-shadow;
    .no-transactions-text{
      width: 100%;
      font-size: 1.2em;
      color: $text-black;
      text-align: center;
    }
  }
  &.grant{
    height: 100%;
    padding: 0 8.5% 0 5%;
    box-shadow: 0 1px 5px 0 $wallet-shadow;
  }

  &.contacts {
    height: 59px;

    margin-top: 2%;
    padding: 2.5em;
  }
}