@import "../../../styles/main.scss";

.roche-transactions-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-right: 81px;
  padding-left: 81px;
  background-color: $background-color;

.balance-container{
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  margin-bottom: 20px;
  .tokens-balance {
    display: flex;
    flex-direction: row;
    align-items: center;
    .tokens-balance-text{
      font-family: Comfortaa, serif;
      font-size: 18px;
    }
  }
  .tokens-balance-col-2 {
    margin-top: 3px;
    margin-left: 10px;
    font-size: 16px;
    font-family: Comfortaa, serif;
  }
  &.with-balance{
    margin-top: 10px;
    margin-bottom: 5px;
  }
}
  .header {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    margin-bottom: 10px;
    background-color: transparent;

    .select-multiple-container {
      margin-left: 30px;
    }

    .multiple-download-button-container {
      margin-left: 30px;
    }

    .button {
      width: auto;
      padding: 2px 10px;
      margin-left: 30px;

      i {
        font-size: 25px;
      }
    }

    .title {
      margin-right: 43px;
      font-size: 26px;
      font-family: $font-Comfortaa;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.75px;
      color: $font-main-color;
    }
    .header-bar{
      display: flex;
      width: 100%;
      height: auto;
      margin-top: 5px;
      background-color: transparent;
    }
  }

  .roche-transactions-table {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    // to avoid underline on tooltip
    a {
      text-decoration: none;
    }

    .wrap {
      overflow-wrap: anywhere;
    }

    .transaction-tooltip {
      opacity: 1;
      border-radius: 6px;
      box-shadow: 0 2px 11px 0 rgba(94, 94, 94, 0.1);
      padding: 15px 10px 15px 10px;

      font-family: $font-OpenSans;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $font-main-color;
      text-underline: none !important;

      &.paragraph {
        padding: 30px 20px 30px 20px;
      }
    }

    .status-container {
      display: flex;
      align-items: center;
      flex: 1;

      .status {
        &.pending {
          color: #d0a754;
        }

        &.rejected {
          color: #c75959;
        }

        &.validated {
          color: $nexus-primary-green;
        }

        &.validationerror {
          color: #c75959;
        }

        &.errorsap {
          color: #c75959;
        }

        &.paid {
          color: #848484;
        }
      }

      .error-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 1;

        .status-error-indicator {
          height: 8px;
          width: 8px;
          background-color: #c75959;
          border-radius: 30px;
        }
      }
    }

    .header-with-checkbox {
      display: inline-flex;
      align-items: center;

      input {
        margin-left: 14px;
      }
    }
  }
}



.swal2-popup {
  font-size: 1.5rem !important;
}
