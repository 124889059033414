body {
  display: flex;
  flex: 1;
  min-height: 100vh;
  background-color: #f0f0f0; }

#root {
  display: flex;
  flex: 1;
  background-color: #f0f0f0; }

.main-container {
  margin: 50px;
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  flex: 1;
  justify-content: center; }

.clickable {
  cursor: pointer; }

h1, h2, h3, h4, h5, h6, button {
  font-family: "CoconPro", sans-serif; }

h4 {
  letter-spacing: 0.04em; }

label, li, p, .title {
  font-family: "CoconPro", sans-serif; }

p {
  font-weight: 300; }

label {
  font-weight: 300; }

input, textarea {
  font-family: "Roboto", serif;
  font-size: 17px;
  min-width: 0;
  width: 100%; }

a {
  cursor: pointer; }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */ }

.row {
  align-items: flex-start !important; }

@media only screen and (max-width: 768px) {
  .header-right {
    border-left: 1px white solid; } }

.error-message {
  margin: 0;
  opacity: 0;
  color: red; }
  .error-message.show {
    opacity: 1;
    transition: opacity 500ms ease-in; }

.success-message {
  margin: 0;
  opacity: 0; }
  .success-message.show {
    opacity: 1;
    transition: opacity 500ms ease-in; }

.custom-loader {
  display: flex;
  justify-content: center;
  margin: 30px; }

.empty {
  display: flex;
  justify-content: center;
  font-size: 18px; }

.tiitle {
  opacity: 0.8;
  font-family: CoconPro, sans-serif;
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #011415; }

div._loading_overlay_wrapper {
  position: unset; }

.i-row, .grey-row {
  display: flex;
  width: 100%;
  justify-content: space-between; }

.grey-row {
  background-color: #f5f5f5; }
  .grey-row .grey-header {
    color: #2cb095;
    font-weight: 700; }
  .grey-row > * {
    padding: 1.2em; }
  .grey-row.big-row .grey-header {
    font-size: 15pt;
    padding: 1em .5em; }
  .grey-row.big-row > div {
    width: 100%;
    padding: .2em;
    padding-left: 1em;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    border-right: 1px solid #ccc; }
    .grey-row.big-row > div:last-child {
      border-right: none; }
    .grey-row.big-row > div > div:first-child {
      position: relative;
      font-size: 9pt;
      font-weight: 700;
      padding: .5em; }
      .grey-row.big-row > div > div:first-child::after {
        content: " ";
        position: absolute;
        top: 80%;
        left: .5em;
        height: 2px;
        width: 2em;
        background-color: #2cb095; }

.btn-det {
  color: #FFF;
  background-color: #2cb095;
  height: 100%;
  min-width: max-content;
  height: auto;
  display: flex !important;
  align-items: center;
  padding: 0.5em 4em;
  border-radius: 5px; }

.ml1em {
  margin-left: 1em !important; }

.subt-label {
  font-size: 15pt;
  font-weight: 700;
  padding: .5em; }

table.adevtable tr td:first-child {
  width: 7em; }

table.adevtable tr td:nth-child(2) {
  width: 15em; }

table.adevtable tr td:nth-child(4) {
  width: 12em; }

table.EvidencesTable tr td.red-dot {
  position: relative; }
  table.EvidencesTable tr td.red-dot::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 1em;
    margin: auto;
    width: 5px;
    height: 5px;
    background-color: #c75959;
    border-radius: 50%; }

.container-icon-save{
  margin-top: 9px;
}