@import '../../../styles/styles.scss';

.wallet-header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 30px;
  box-shadow: 0 4px 20px 0 rgba(44, 176, 149, 0.25);
  border: solid 1px$light-gray-fill;
  background-color: $nexus-secondary-green;
  width: 100%;
  min-width: 300px;
  height: 34%;
  min-height: 120px;
  padding: 0 5% 0 10% ;
  margin-bottom: 1%;

}
.wallet-home-card {
  overflow: hidden !important;
}
.wallet-home-screen-container{
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 10vw 0 10vw;
}
.wallet-card-header{
  height: 30%;
}
.wallet-card-body{
  padding: 0 10% 0 10%;
  height: 90%;
  position: relative;
  bottom: 20%;

}

.wallet-balance-container{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  width: 55%;
  margin:0 12% 0 12%;
}
.wallet-balance-title{
  font-family: $font-Comfortaa;
  font-size: 1em;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #FFFFFF;
}
.wallet-info-container{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: calc(36em - 5%/2);
  min-width: 25em;
  max-width: 31.5em;
}
.wallet-icon-box-container{
  width:85px;
  aspect-ratio: 1/1;
}


.back-link{
  margin: 45px 0 25px 0;
}