@import "../../../styles/main.scss";
.registrar-profile-screen {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;

  .registrar-profile-main-container {
    display: block;
    flex: 1;
    flex-direction: column;
    align-items: center;
    padding-top: 48px;
    max-width: 1091px;

    .registrar-institution-card {
      margin-top: 14px;
    }

    .private-key-button {
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 1091px;
      height: 56px;
      border-radius: 7px;
      background-color: white;
      border: solid 1px #22a996;
      margin: 48px 20px 58px 20px!important;

      &:hover {
        cursor: pointer;
      }

      i {
        font-size: 22px;
        color: #079398;
      }

      .text {
        font-family: $font-Comfortaa;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #079398;
        text-decoration: underline;
        margin-left: 7px;
      }

      &.fill {
        margin-right: 12px;
        margin-left: 0;
        background-image: linear-gradient(to bottom, #28ad95, #059299);

        i, .text {
          color: white!important;
        }
      }
    }

    .show-key-container {
      display: flex;
      align-items: center;
      margin-top: 18px;
      margin-bottom: 20px;
      margin-left: 20px;
      align-self: flex-start;

      i {
        -webkit-text-stroke: 1px #656565;
        font-size: 18px;
        color: #656565;
        margin-right: 9px;
      }

      span {
        font-family: $font-Comfortaa;
        font-size: 15px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-decoration: underline;
        color: #5a5a5a;
      }

      span:hover{
        cursor: pointer;
      }
    }
  }
}