@import '../../../styles/styles.scss';

.container-shippingAddress {
  max-width: 1082px;

  .container-userUnknown {
    display: flex;
    height: auto;

    justify-content: space-between;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;

    .container-search-field {
      width: 100%;
      padding-right: 1em;
    }

    .container-btn {
      height: auto;
      display: flex;

      .btn-address {
        display: flex;
        width: 100%;
        gap: 3px
      }
    }

    .container-title {
      width: max-content;
      display: block;
    }

    .title {
      font-family: Comfortaa;
      font-size: 16px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
    }

    .body-card-row {
      width: 100%;
    }

    .download-button {
      height: 39px;
    }
  }

  .container-card-row {
    margin: 5% 0 5% 0;
    height: 50%;
  }
  .loader-container{
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.body-linkAccess {
  width: 100%;

  .container-linkAccess {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 5% 0 0 0;

    .title-linkAccess {
      padding-left: 3.2px;
      font-family: Comfortaa;
      font-size: 14px;
      font-weight: 600;
      text-align: left;
      color: $green-increase;
    }
  }
}

.container-line {
  display: flex;
  border-bottom: 2px solid $light-gray-fill;

  .container-titleBar {
    width: max-content;
  }

  .title {
    font-family: Comfortaa;
    font-size: 16px;
    font-weight: 600;
    text-align: left;
  }
}