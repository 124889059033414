@import "../../../styles/styles.scss";

.sidebar, .sidebar-hb {
  background-color: white;
  display: flex;
  flex-direction: column;
  width: 105px;
  min-width: 105px;
  //min-height: calc(100vh - 80px);
  border: solid 1px $gray;

  .sidebar-bottom {
    display: flex;
    font: $header-font;
    font-size: 12px;
    padding-bottom: 20px;
    align-items: center;
    justify-content: center;
  }

  .sidebar-list {
    flex-grow: 1;
    list-style-type: none;
    padding: 0;

    a {
      text-decoration: none;
    }
  }

  .sidebar-tooltip {
    opacity: 1;
    border-radius: 0 8px 8px 0;
    padding: 25px 84px 25px 10px;
    margin-left: 0!important;

    font-family: $font-Comfortaa;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.46px;
    color: $font-main-color;
    text-underline: none;
  }

  .sidebar-item {
    display: flex;
    align-items: center;
    color: $light;
    font-size: 1.5em;
    padding: 20px 10px 20px 0;

    .indicator {
      width: 10px;
      height: 61px;
      border-radius: 16px;
      margin-left: -4px;

      &.show {
        box-shadow: 2px 3px 11px 0 rgba(1, 172, 253, 0.74);
        background-color: $nexus-secondary-green;
      }
    }

    .icon {
      display: flex;
      flex: 1;
      justify-content: center;

      i {
        font-size: 30px;
      }

      &.selected {
        i {
          color: $nexus-secondary-green;
        }
      }

    }
  }

  .sidebar-link {
    text-decoration: none;
  }
}

// Responsive design

.hamburger {
  display: none;
}

@media only screen and (max-width: 768px) {
  .sidebar {
    display: none;
  }

  .hamburger {
    position: absolute;
    z-index: 999;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    img {
      padding: 10px;
    }

    .close {
      color: #FFFFFF;
      text-align: end;
      padding: 3px;
      border-bottom: solid 1px rgb(200,200,200);
    }

    .close{
      a:hover {
        text-decoration: none;
        color: #FFFFFF;
      }

      a {
        color: #FFFFFF;
      }
    }

    .sidebar-hb {
      flex-grow: 1;
    }

    max-width: 220px;
  }
}

.ic-notif {
  position: absolute;
  left: 20px;
  font-size: 11px;
  font-weight: bold;
  font-family: sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 5px;
  border-radius: 9999px;
  background-color: #c75959;
  color: #FFF;
}

