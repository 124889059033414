@import '../../../styles/main.scss';
@import '../../../styles/styles.scss';

.i-row{
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.grey-row {
    @extend .i-row;
    background-color: #f5f5f5;

    .grey-header{
        color: $nexus-primary-green;
        font-weight: 700;
    }
    > * {
        padding: 1.2em;
    }

    &.big-row{
        .grey-header{
            font-size: 15pt;
            padding: 1em .5em;
        }
        > div {
            width: 100%;
            padding: .2em;
            padding-left: 1em;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;
            border-right: 1px solid #ccc;
            &:last-child {border-right: none;}
            > div {
                &:first-child {
                    position: relative;
                    font-size: 9pt;
                    font-weight: 700;
                    padding: .5em;
                    &::after{
                        content: " ";
                        position: absolute;
                        top: 80%;left: .5em;
                        height: 2px;width: 2em;
                        background-color: $nexus-primary-green;
                    }
                }
            }
        }
    }
}
.btn-det {
    color: #FFF;
    background-color: $nexus-primary-green;
    height: 100%;
    min-width: max-content;
    height: auto;
    display: flex!important;
    align-items: center;
    padding: 0.5em 4em;
    border-radius: 5px;
}
.ml1em{margin-left: 1em!important;}
.subt-label{
    font-size: 15pt;
    font-weight: 700;
    padding: .5em;
}

table.adevtable {
    tr {
        td:first-child {
            width: 7em;
        }
        td:nth-child(2) {
            width: 15em;
        }
        td:nth-child(4) {
            width: 12em;
        }
    }
}
.container-table{
    padding-bottom: 20px;
}
table.EvidencesTable {
    tr{
        td.red-dot{
            position: relative;
            &::before{
                content: "";
                position: absolute;
                top: 0;bottom: 0;left: 1em;
                margin: auto;
                width: 5px;height: 5px;
                background-color: #c75959;
                border-radius: 50%;
            }
        }
    }
}
.wrapper-accessLink{
    width: 140px;
}