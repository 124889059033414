@import "../../../styles/main.scss";

.no-photo-modal {
  .header {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;

    i {
      font-size: 70px!important;
      color: #d22727!important;
      -webkit-text-stroke: unset!important;
    }

    .title {
      font-family: $font-CoconPro!important;
      font-size: 24px!important;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #d22727!important;
      margin-top: 29px;
    }
  }

  .body {
    display: flex;

    p {
      font-family: $font-OpenSans;
      font-size: 21px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #626262;
    }
  }

  .footer {
    display: flex;
    justify-content: center;
  }
}