@import "../../../styles/styles.scss";

.input-container {
  height: 42px;
  width: 100%;
  border-radius: 4px;
  border: none;
  background-color: #f3f3f3 !important;
  margin-bottom: 30px;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;

  &.white {
    background-color: #ffffff !important;
    border: solid 1px $light-gray-fill;
    border-radius: 7px;

    input {
      background-color: #ffffff !important;
      border: none;
    }

    &.disabled {
      background-color: $background-color !important;

      input {
        background-color: $background-color !important;
        border: none;
        caret-color: transparent;
      }
    }
  }

  &.pill {
    height: 50px;
    border-radius: 20px;
    border: solid 1px $light-gray-fill !important;
    background-color: #ffffff !important;
    margin-bottom: 15px;
  }

  &.error {
    border: solid 1px $icon-error !important;
  }

  &.disabled {
    .input {
      color: grey !important;
      caret-color: transparent;
    }
  }

  &.shadow {
    border-radius: 7px;
    box-shadow: 0 4px 30px 0 rgba(216, 216, 216, 0.82);
    border: solid 1px $wallet-color-light;
    background-color: $wallet-color-white;
  }

  &.border {
    height: 45px;
    border-radius: 7px;
    border: solid 1px #dfe2e6 !important;
    max-width: 480px;
  }

  .input {
    border: none;
    padding-left: 24px;
    padding-right: 24px;
    border-radius: 4px;
    font-family: $font-OpenSans, sans-serif;
    font-size: 20px;

    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    flex-grow: 1;
    color: $font-main-color;

    &.pill {
      background-color: rgba(255, 255, 255, 1);
      border: none;
      padding: 4px 8px;
      border-radius: 20px;
      font-family: $font-Comfortaa;
      font-weight: bold;
      font-size: 16px;
    }

    &.wallet {
      font-weight: 600;
      font-family: Comfortaa;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: $title-color;
    }
  }

  ::placeholder {
    color: $icon-gray;
  }

  input:focus {
    outline: none;
  }

  .show-password-icon {
    font-size: 1.5em;
    margin-right: 10px;
    cursor: pointer;
  }

  i {
    font-size: 20px;
    color: $icon-gray;
    padding-right: 6px;
    padding-left: 18px;
    margin-left: 0;

    &.icon-eye {
      padding-right: 17px !important;
      padding-left: 0 !important;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.input-label {
  font-size: 14px;
  margin-top: 0;
  color: #013337;
  font-family: $font-Comfortaa;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-bottom: 10px;

  &.pill {
    font-size: 16px;
    color: $title-color;
  }

  &.required:after {
    content: " *"
  }

  &.big {
    font-size: 18px;
  }
}