@import "../../../../styles/styles.scss";

.round-icon-container{
  height: 100%;
  width: 100%;
  border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

  &.white{
    background-color: $white; ;
  }
  &.light-green{
    background-color:$light-green-background ;
  }
}