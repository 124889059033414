@import '../../../styles/main.scss';
.terms-and-conditions-modal {
    .footer {
        margin-left: 24px;
        flex-direction: column;

        .checkbox-label {
            margin-left: 10px;
            &:hover {
                cursor: pointer;
            }
        }

        .terms-and-conditions-row {
            display: flex;
        }

        .container-btns {
            display: flex;
            margin-top: 24px;
        }
        button {
            margin-top: 15px;
        }
    }
}
