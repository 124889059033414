.audit-comments-container {
    display: flex;
    flex-flow: column nowrap;
    flex: 0;
    width: 100%;
    background-color: white;
    padding: 12px;
    border-radius: 16px;
    border: 1px solid #d6d6d6;

    &.error-box {
        border: 2px solid #c95857;
    }

    > .title {
        height: 24px;
        padding: 0 16px;
        display: flex;
        flex: 0;
        justify-content: center;
        align-items: center;
        align-self: flex-start;
        border-radius: 15px;
        font-family: 'Comfortaa';
        font-weight: 700;
        white-space: nowrap;

        &.red-box {
            background: rgba(#c95857, 0.2);
            color: #c95857;
            border: solid 1px #c95857;
        }
    }

    > .comments-container {
        display: flex;
        justify-content: center;
        flex-flow: column nowrap;
        width: 100%;
        flex: 0;
        padding-left: 16px;

        height: unset !important;

        > .comment-item {
            display: flex;
            flex-flow: row nowrap;
            flex: 0;
            height: unset;

            > p {
                height: 16px;

                &.text {
                    margin-left: 8px;
                    font-family: 'Roboto', sans-serif;
                }
            }
        }

        > .empty-text {
            font-size: 18px;
            font-family: 'Roboto', sans-serif;
            color: #afb3ba;
            margin: 0;
        }
    }
}
