@import "../../../styles/main.scss";

.custom-select-container {
  display: flex;
  width: 100%;
  position: relative;
  justify-content: center;

  .custom-select {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 10px 5px 10px 5px;
    border: solid 1px #dfe2e6;
    border-radius: 4px;
    background-color: #ffffff;

    i {
      -webkit-text-stroke: 1px #979797;
      font-size: 20px;
      color: $icon-gray;
      margin-left: 19px;
    }

    .custom-select-icon {
      position: absolute;
      right: 16px;
      font-size: 20px;
      color: #636363;
    }

    .customer-icon {
      background: url("../../../assets/icons/icon-timer.svg") no-repeat right;
      background-size: 24px;
      width: 30px;
      height: 24px;
      margin-right: 20px;
    }

    .custom-select-value {
      font-family: $font-Comfortaa;
      font-size: 16px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #383838;
      margin-left: 9px;
    }

    .custom-select-placeholder {
      font-family: $font-Comfortaa;
      font-size: 16px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $icon-gray;
      margin-left: 9px;
    }

    &.rounded {
      height: 50px;
      border-radius: 20px;
      box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.05);
      border: solid 1px #dfe2e6;
      background-color: #ffffff;
    }

    &.error {
      border: solid 1px $icon-error !important;
    }

    &.disabled {
      background-color: #f1f1f1 !important;
    }
  }

  .custom-select:hover {
    cursor: pointer;
  }

  .custom-select-options-container {
    position: absolute;
    z-index: 999;
    display: none;
    padding: 27px 0 27px 0;
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: 0 2px 14px 0 rgba(118, 118, 118, 0.5);
    width: 100%;

    &.csoc_mw {
      width: max-content !important;
    }

    &.show {
      display: flex;
      flex-direction: column;

      &.scrollable {
        max-height: 300px;
        overflow: auto;
      }
    }

    .custom-select-option {
      display: flex;
      align-items: center;
      height: fit-content;
      padding: 5px 25px;

      .custom-select-option-label {
        font-family: $font-Comfortaa;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #414243;
      }
    }

    .custom-select-option:hover {
      background-color: #efefef;
      cursor: pointer;
    }

    &.rounded {
      margin-top: 50px;
      width: 90%;
      border-radius: 0 0 10px 10px;
    }

    &.mxh {
      max-height: 15em;
      overflow: overlay;
    }

    &.coptop {
      bottom: 30px;
    }
  }

  >.rounded.i-screen {
    border-radius: 7px !important;

    &:first-child {
      height: 42px;
    }

    &.is-small:first-child {
      justify-content: center;
      height: 2em;
    }

    &.is-small span.custom-select-value {
      margin: 0 .3em 0 0;
    }
  }

  .custom-select-searchterm {
    text-align: center;

    font-family: 'Comfortaa';
    text-transform: lowercase;
    font-weight: 500;
    margin-top: -1em;
    margin-bottom: .5em;

    input {
      padding: 0 0.5em;
      border: none;
      border-bottom: 1px solid #0f9997;
      outline: none !important;
      background: none;
    }

  }
}

.custom-select-container:focus {
  outline: none;
}