@import '../../../../styles/styles';

.input-paste-container{
  position: relative;
  width: 100%;
}
.add-new-pill {
  height: 100%;
  border-radius: 7px !important;
  border: solid 1px #dfe2e6 !important;
  font-family: $font-Comfortaa !important;
  font-size: 16px !important;
  color: $text-black !important;
  font-weight: normal !important;
  text-overflow: ellipsis;
  &.with-icon{
    padding-right: 40%;
  }
}
.paste-input{
  height: 5vh;
  max-height: 90px;
  min-height: 30px;
  margin-bottom: 4%;
  border: 0 !important;
}

.link-access-container{
  width: 50%;
  padding-right: 5%;
  position: absolute;
  top: 1vh;
  right: 0;
  display: flex;
  align-content: center;
  justify-content: flex-end;
  &.base{
    height: 3vh;

  }
  &.add-contact{
    height: calc(5vh + 50px);

  }
}
.paste-address{
  height: auto;
  width: auto;
  display: flex;
  align-items: center;
  font-size: 10%;

  &.add-contact{
    top: 0;
  }
}
@media only screen and (max-width: 1400px) {
  .link-access-container {
    &.add-contact {
      display: none;
    }
  }
  .add-new-pill {
    &.with-icon {
      padding-right: 5%;
    }
  }
}