@import "../../../styles/main.scss";
// colors
$step-grey: #b4b4b4;

.create-private-key-wizard {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  margin-left: 20px;

  .steps-header {
    display: flex;

    .step {
      display: flex;
      justify-content: center;
      align-items: center;

      i {
        font-size: 32px;
        color: $nexus-secondary-green;
      }

      .step-number {
        // background
        background-color: transparent;
        line-height: 32px;
        width: 32px;
        border-radius: 50px;
        border: solid 1px $step-grey;

        // text
        font-size: 16px;
        font-family: $font-Comfortaa;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        color: $step-grey;
        text-align: center;
      }

      .step-name {
        margin-left: 5px;
        font-family: $font-Comfortaa;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $step-grey;
      }

      &.selected {
        .step-number {
          // background
          background-color: $nexus-secondary-green;
          border: none;
          // text
          color: #ffffff;
        }

        .step-name {
          color: rgba(0, 0, 0, 0.85);
        }
      }
    }
    .step-separator {
      display: flex;
      flex: 1;
      border: solid 1px #cdcdcd;
      margin-left: 15px;
      margin-right: 15px;
    }
  }

  .step-card {
    display: flex;
    border-radius: 7px;
    box-shadow: 0 0 19px 0 rgba(228, 228, 228, 0.5);
    background-color: #ffffff;
    margin-top: 24px;
    margin-bottom: 24px;
    max-width: 888px;

    // common an all steps
    .title {
      font-family: $font-CoconPro;
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #4a4a4a;
    }

    .subtitle {
      font-family: $font-Comfortaa;
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      color: #525252;
      margin-top: 19px;
    }

    .green-highlight {
      color: $nexus-secondary-green;
    }

    hr {
      width: 100%;
      border: solid 1px #f3f3f3;
    }
    .line-separator {
      margin-top: 20px;
      margin-bottom: 20px;
      width: 100%;
    }

    .word {
      // word container
      display: inline-flex;
      padding: 8px;
      margin: 16px;
      border-radius: 10px;
      border: dashed 1px #c1c1c1;

      //text
      font-family: $font-Comfortaa;
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #767676;
    }

    .first-step {
      display: flex;
      padding: 30px 30px 20px 79px;

      .left-column {
        display: flex;
        flex: 1;
        flex-direction: column;
        margin-top: 66px;

        img {
          height: fit-content;
          width: fit-content;
        }

        p {
          font-family: $font-CoconPro;
          font-size: 24px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #02454a;
          margin-top: 24px;
        }

        .button {
          margin-top: 50px;
        }
      }

      .right-column {
        display: flex;
        flex: 1;
      }
    }

    .second-step-a {
      display: flex;
      flex-direction: column;
      padding: 33px 50px 33px 50px;

      .words-container {
        display: block;

        // used on ImportPrivateKeyWizard
        input {
          width: 117px;
          height: 36px;
          margin-right: 14px;
          margin-top: 23px;
          padding-left: 15px;
          padding-right: 15px;
          border-radius: 10px;
          border: solid 1px #c1c1c1;
          background-color: $background-color;

          // font
          font-family: $font-OpenSans, sans-serif;
          font-size: 20px;
          font-weight: 600;
          color: $font-main-color;
        }

        input:focus {
          outline: none;
        }
      }

      .buttons-row {
        display: flex;
        justify-content: center;
      }

      .button {
        align-self: center;
        margin-bottom: 15px;
        margin-right: 15px;
      }
    }

    .second-step-b {
      display: flex;
      flex-direction: column;
      padding: 33px 50px 80px 50px;

      .step-header {
        display: flex;
        justify-content: space-between;

        .first-column {
          display: flex;
          flex-direction: column;
        }

        .second-column {
          display: flex;

          i {
            font-size: 28px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $nexus-secondary-green;
          }

          i:hover {
            cursor: pointer;
          }
        }
      }

      .subtitle {
        font-size: 20px;
      }

      .words-to-order-container {
        display: block;

        .word {
          display: none;
          border: solid 1px #c1c1c1;
          background-color: #f1f1f1;

          &:hover {
            cursor: pointer;
          }

          &.show {
            display: inline-flex !important;
          }
        }

        &.last {
          margin-top: 100px;
        }
      }

      .buttons-row {
        display: flex;
        justify-content: center;
      }

      .button {
        margin-top: 28px;
        align-self: center;
        margin-right: 15px;
      }
    }

    .second-step-c {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 87px 105px 60px 105px;

      i {
        font-size: 70px;

        &.red {
          color: $icon-error;
        }
        &.green {
          color: $icon-success;
        }
      }

      .result-text {
        opacity: 0.88;
        font-family: $font-Comfortaa;
        font-size: 24px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.42;
        letter-spacing: normal;
        text-align: center;
        color: $title-color;
        margin-top: 47px;
      }

      hr {
        margin-top: 50px;
      }

      .button {
        margin-top: 20px;
      }
    }
  }
  @media (max-width: 768px) {
    .step-card {
      .second-step-a {
        padding: 33px 10px 33px 30px;
      }
      .second-step-b {
        padding: 33px 10px 33px 30px;
        .words-to-order-container {
          &.last {
            margin-top: 30px;
          }
        }
      }
      .second-step-c {
        padding: 40px 30px 40px 30px;
      }
    }
  }
  @media (max-width: 991px) {
    .step-card {
      .first-step {
        padding: 30px 30px 20px 30px;
        .right-column {
          flex: none;
          img {
            width: 300px;
          }
        }
      }
    }
  }
  @media (max-width: 688px) {
    .step-card {
      .first-step {
        padding: 30px 30px 20px 30px;
        .right-column {
          display: none;
        }
        .left-column {
          .button {
            align-self: center;
          }
        }
      }
      .second-step-a, .second-step-b {
        .buttons-row {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }
    }
  }
}
