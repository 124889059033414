@import '../../../styles/styles.scss';

.nexus-table {
    border: none;
    width: 100%;

    &.overflow-visible {
        overflow: visible;
        .rt-table {
            overflow: visible;
        }
        .rt-tbody {
            overflow: visible;
        }
    }

    .rt-table {
        background-color: white;
        border-radius: 3px;
        border: solid 1px $gray;
        box-shadow: 0 2px 13px 0 rgba(240, 240, 240, 0.5);
    }

    .rt-thead .rt-tr {
        padding: 0;
        background-color: #f7f7f7;
    }

    .rt-resizable-header-content {
        width: 100%;
    }

    .rt-tbody {
        padding-bottom: 35px;

        &:hover {
            cursor: pointer;
        }

        .rt-tr {
            padding: 0;

            .action-buttons-container {
                display: flex;
                flex: 1;
                justify-content: center;
                i {
                    opacity: 0.69;
                    -webkit-text-stroke: 1px $font-main-color;
                    font-size: 18px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: $font-main-color;
                    margin-right: 10px;
                    margin-left: 10px;

                    &:hover {
                        cursor: pointer;
                        text-underline: none;
                    }
                }

                a {
                    text-decoration: none;
                }

                .action-button-tooltip {
                    opacity: 1;
                    border-radius: 6px;
                    box-shadow: 0 2px 5px 0 rgba(94, 94, 94, 0.05);

                    font-family: $font-Comfortaa;
                    font-size: 12px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: #ffffff;
                    padding: 10px 25px;
                    text-underline: none;
                }
            }

            .action-checkbox-container {
                display: flex;
                justify-content: center;

                input[type='checkbox'] {
                    width: auto;
                }
            }
        }
    }

    &.empty {
        .rt-tbody {
            margin: 0;
        }

        .rt-noData {
            margin: 0;
            padding: 0;
            z-index: 0;
            top: auto;
            background: transparent;
            font-family: $font-Comfortaa;
        }
    }
    .rt-head {
        box-shadow: none !important;
    }
    .rt-tr-group {
        &:hover {
            cursor: default;
        }
    }
    .rt-tr-group:nth-child(odd) {
        background-color: white;
    }
    .rt-tr-group:nth-child(even) {
        background-color: #f7f7f7;
    }

    .nexus-header {
        display: flex;
        background-color: #f7f7f7;
        border-right: none !important;
        outline: none;
        border-right: solid 1px #e4e4e4 !important;
        padding-left: 15px !important;
        padding-right: 15px !important;

        &.extra-div {
            div:first-child {
                width: 100%;
                display: flex;
                justify-content: space-between;
            }
        }

        div {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
            width: 100%;
            &:hover {
                cursor: pointer;
            }
        }

        h5 {
            font-family: $font-Comfortaa !important;
            color: $title-color;
            font-size: 16px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            white-space: initial;
            word-wrap: break-word;
        }

        &.left {
            text-align: left;
        }

        &.center {
            text-align: center;
            justify-content: center;
        }
        &.between {
            justify-content: space-between;
            width: 100%;

            :first-child {
                width: 100%;
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                .arrows {
                    margin-top: 0.5rem;
                }
            }
        }
    }

    .nexus-header-arrows {
        width: 100%;
        display: flex;
        padding-left: 1rem;
        text-align: center;
        justify-content: space-between;
        align-items: center;

        .arrows {
            display: flex;
            flex: 0;
            flex-direction: column;
            justify-content: center;

            &.mt {
                margin-top: 0.5rem;
            }
        }
        &.top {
            align-items: flex-start;
        }
    }

    .nexus-header:last-child {
        border-right: none !important;
    }

    .nexus-cell {
        display: flex;
        border-right: none !important;
        text-align: center;
        border-right: solid 1px #e4e4e4 !important;
        padding-left: 15px;
        padding-right: 15px;

        .text {
            font-family: $font-OpenSans, sans-serif;
            font-size: 14px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $font-main-color;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;

            &.status {
                font-weight: normal;

                &.active {
                    color: $nexus-primary-green;
                }
                &.inactive {
                    color: #c75959;
                }
                &.A {
                    color: $nexus-primary-green;
                }
                &.B {
                    color: #c75959;
                }
                &.C {
                    color: #c88888;
                }
                &.F {
                    color: #c88888;
                }
            }
        }

        &.left {
            text-align: left;
        }

        &.center {
            text-align: center;
            justify-content: center;
        }

        &.overflow {
            overflow: visible;
        }
    }
    .nexus-cell:last-child {
        border-right: none !important;
    }

    button {
        border: none;
        padding: 0;
        margin: 0;
        background-color: transparent;
    }

    button:focus {
        outline: 0;
    }
}
