@import "../../../styles/main.scss";
@import "../../../patient/components/PatientDoseScreen/PatientDoseScreen.scss";

.registration-screen {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 58px 129px 58px 79px;

  .registration-header {
    display: flex;
    justify-content: space-between;
    background-color: transparent;

    .button {
      background-color: $nexus-secondary-green;
      border: $nexus-secondary-green;
      .button-body {
        .text {
          font-size: 14px;
          font-weight: 600;
          margin-left: 11px;
        }

        i {
          font-size: 25px;
        }
      }
    }
  }

  .fix-height {
    height: 44px;
    margin-bottom: 12px;
  }

  .title {
    font-family: $font-Comfortaa;
    font-size: 26px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.75px;
    color: $font-main-color;
  }

  .checkboxes-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    margin-left: 14px;
  }

  .validation-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 33px;
    padding: 37px 146px 33px 148px;
    border-radius: 16px;
    box-shadow: 0 2px 17px 0 rgba(200, 200, 200, 0.5);
    background-color: #ffffff;

    .field-container {
      display: flex;
      width: 100%;
      flex-wrap: wrap;

      .column {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-right: 18px;

        .input-container, .select-input-container {
          min-width: 200px;
        }
      }
    }
    .button {
      margin-top: 20px;
    }
  }
  .scan-wrapper{
    margin-top: 42px;

    .scan-header {
      width: 100%;
      display: flex;
      color: #FFF;
      > div {
        width: 100%;
        text-align: center;
        background-color: #c6c6c6;
        font-family: 'Comfortaa';
        font-weight: 600;
        margin-left: 5px;
        padding: .7em;
        border-radius: 3px 3px 0 0;

        &:first-child {
          margin-left: 0;
        }

        &.selected {
          background-color: $nexus-primary-green;
        }
      }
    }

    hr.separator {
      height: 1.3em;
      background-color: #f0f0f0;
      border: none!important;
      width: calc(100% + 51px + 20px)!important;
      margin-left: -51px;
      margin-right: -25px;
    }

    .scan-card {
      display: flex;
      width: 100%;
      flex-direction: column;
      padding: 59px 20px 60px 51px;
      border-radius: 3px;
      box-shadow: 0 2px 17px 0 rgba(200, 200, 200, 0.5);
      background-color: #ffffff;

      .title {
        font-size: 20px;
        letter-spacing: -0.57px;
        color: #4d4d4d;
      }

      hr {
        width: 100%;
        margin-top: 16px;
        margin-bottom: 12px;
        border: solid 1px #e8e8e8;
      }

      .product-card-container {
        display: flex;
        flex-wrap: wrap;

        .product-card {
          margin-left: 24px;
          margin-bottom: 24px;
        }
      }

      .error-box {
        width: fit-content;
        margin-left: 24px;
        margin-bottom: 12px;
        .error-msj {
          overflow-wrap: anywhere;
        }
      }

      .button {
        align-self: center;
      }
    }
  }

  .modal {
    .header {
      height: auto;
      width: 100%;
      flex-direction: column;

      i {
        font-size: 70px;
        -webkit-text-stroke: unset;
        margin-bottom: 16px;

        &.red {
          color: $icon-error;
        }
        &.green {
          color: #53bfa9;
        }
      }

      .title {
        font-weight: 600;
        color: $title-color;
      }
    }

    .separator-line {
      display: none!important;
    }
  }

  @media (max-width: 1200px) {
    padding: 58px 40px 58px 40px;
    .validation-card {
      padding: 37px 62px 33px 80px;
    }
  }

  @media (max-width: 992px) {
    .fix-height {
      height: auto;
    }
    .validation-card {
      padding: 37px 22px 33px 22px;
    }
    .scan-card {
      padding: 39px 12px 39px 12px;
      align-items: center;
      .product-card-container {
        justify-content: center;
        .product-card {
          margin-left: 14px;
        }
      }
    }
  }

  @media (max-width: 500px) {
    padding: 38px 20px 58px 20px;
    .registration-header {
      align-items: center;
      .button {
        width: 50px;
        span {
          display: none;
        }
      }
    }
    .scan-card {
      .product-card-container {
        .product-card {
          margin-left: 5px;
        }
      }
    }
  }
}