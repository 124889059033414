@import "../../../styles/main.scss";

.granting-monitor-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-right: 81px;
  padding-left: 81px;
  background-color: $background-color;

  .header {
    display: flex;
    width: 100%;
    height: auto;
    margin-top: 55px;
    margin-bottom: 23px;
    background-color: transparent;

    .title {
      margin-right: 43px;
      font-size: 26px;
      font-family: $font-Comfortaa;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.75px;
      color: $font-main-color;
    }

    .button {
      margin-left: 10px;
    }

    .download-button {
      height: 100%;

      i {
        font-size: 25px;
        margin-right: 5px;
      }
    }
  }

  .granting-monitor-table {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .header-with-checkbox {
      display: inline-flex;
      align-items: center;

      input {
        margin-left: 14px;
      }
    }
  }
}
