@import "../../../styles/main.scss";

.card {
  width: 100%;
  background-color: #FFF;
  border-radius: .5em;
  margin-bottom: 2em;

  font-family: 'Comfortaa';
  font-weight: 300;

  --main-color: #12b89c;
  --second-color: #1a8d76;
  --third-color: #045847;
  box-shadow: 0 2px 17px 0 rgba(200, 200, 200, 0.5);
}

.card-head {
  width: 100%;
  background: url("../../../assets/images/profile-green-bar-small.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: .5em 2em;
  color: #FFF;
  font-weight: 500;
  font-size: 15pt;
  border-radius: .5em .5em 0 0;
}

.card-head-mod {
  background: #2CB095;
  width: 100%;
  height: 1em;
  align-items: center;
  padding: 1.2em;
}

.card-head-props {
  display: flex;
  justify-content: space-between;
}

.card-body {
  width: 100%;
  padding: 0em 1.5em 2em 1.5em;

  &.between {
    display: flex;
    justify-content: space-between;
  }

  &.wallet {
    overflow: hidden;
    padding: 5% 8% 0 8%;
  }

  &.center {
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
  }
}

.no-padding {
  padding: 0;
}

.side-padding-m .card-body {
  padding-left: 4em;
  padding-right: 4em;
}

.side-padding-l .card-body {
  padding-left: 6em;
  padding-right: 6em;
}

.card .card-label {
  color: var(--main-color);
  font-weight: 700;
}

.card .card-rlink {
  width: 100%;
  color: var(--main-color);
  font-weight: 700;
  text-decoration: underline;
}

.card .card-rlink:hover {
  color: var(--second-color);
}

.card .card-rlink.disabled {
  color:$space-gray;
  pointer-events: none; 
  cursor: not-allowed; 
}

.card .input-container input {
  font-weight: 300;
}

.min-height {
  min-height: 30rem;
}


/* CARD CONTENT */

.card-row {
  display: flex;
}

.card-row>div {
  width: 100%;
  padding-left: 2em;
}

.card-row>div:first-child {
  padding-left: 0;
}

.card-subrow {
  display: flex;

  &.center {
    justify-content: center;
    gap: 1em;
  }

}

.card-subrow>div {
  width: 100%;
  padding-left: 1em;
}

.card-subrow>div:first-child {
  padding-left: 0;
}

.w50 {
  width: 50% !important;
}

.w100 {
  width: 100%;
}


.i-container .react-datepicker-wrapper {
  height: 47px;
  border-radius: 7px;
  width: 100%;

  .react-datepicker__input-container input {
    font-size: 14px;

    &::placeholder {
      color: #DDD;
    }
  }
}

.i-container .react-datepicker {

  box-shadow: 0 2px 20px 5px rgba(200, 200, 200, 0.7);
  border: 0;
  font-family: "Comfortaa";
  border-radius: 1em;

  .react-datepicker__triangle,
  .react-datepicker__triangle::before {
    display: none;
  }

  .react-datepicker__navigation {
    padding: revert;
    width: 2.5em;
    height: 2.5em;
    border-radius: 2em;
    background-color: #FFF;
    box-shadow: 0 0 2px 0 #0005;

    &:hover {
      box-shadow: 0 0 3px 0 #0008;
    }
  }

  .react-datepicker__navigation--next {
    right: -10px;
    border-left-color: #FFF0;
    top: 10em;

    &::before {
      content: ' ';
      position: absolute;
      width: 1em;
      height: 1em;
      top: 1.5px;
      left: 0;
      background-color: #12b89c;
      transform: rotate(45deg);
    }

    &::after {
      content: ' ';
      position: absolute;
      width: 1em;
      height: 1em;
      top: 1.5px;
      left: -2px;
      background-color: #FFF;
      transform: rotate(45deg);
    }
  }

  .react-datepicker__navigation--previous {
    left: -10px;
    border-right-color: #FFF0;
    top: 10em;

    &::before {
      content: ' ';
      position: absolute;
      width: 1em;
      height: 1em;
      top: 1.5px;
      left: 3.5px;
      background-color: #12b89c;
      transform: rotate(45deg);
    }

    &::after {
      content: ' ';
      position: absolute;
      width: 1em;
      height: 1em;
      top: 1.5px;
      left: 5.5px;
      background-color: #FFF;
      transform: rotate(45deg);
    }
  }

  .react-datepicker__month-container {
    float: left;
    padding: 0 2em 0 2em;

    .react-datepicker__header {
      text-align: center;
      background-color: #fff;
      border-bottom: 0;
      border-top-left-radius: 0.3rem;
      padding-top: 8px;
      position: relative;

      .react-datepicker__current-month {
        display: flex;
        padding-left: 10px;
      }

      .react-datepicker__day-names .react-datepicker__day-name {
        color: #DFDFDF;
      }
    }

    .react-datepicker__day--keyboard-selected,
    .react-datepicker__day--selected,
    .react-datepicker__day:hover {
      border-radius: 1rem;
      background-color: #12b89c;
      color: #fff;
    }
  }
}

.i-container .error-next-datepicker+.react-datepicker-wrapper {
  border: 1px solid $icon-error !important;
}

.i-container .bread-crum {
  margin: 0 0 0.7em -0.5em;
  font-family: 'Comfortaa';
  font-weight: 700;

  a {
    color: #0008;
  }

  b {
    color: #000;
  }
}

.i-container .expandable-link {
  position: relative;

  .expandable-container {
    position: absolute;
    bottom: 2em;
    right: 0;
    padding: 1em;
    min-width: 50%;
    z-index: 999;

    background-color: #FFF;
    border-radius: 7px;
    box-shadow: 0 0 3px 0 #0008;
  }
}

.ic-spe {
  position: relative;

  .ic-sub {
    position: absolute;
    top: 10px;
    right: -5px;
    font-size: 10px;
  }

  .ic-notif {
    position: absolute;
    top: -5px;
    right: -5px;

    font-size: 8px;
    font-weight: 800;
    font-family: sans-serif;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #C44;
    color: #FFF;
  }
}