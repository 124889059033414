@import "../../../styles/main.scss";
.dashboard-screen {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: stretch;
  overflow: scroll;
  margin-right: 62px;
  margin-left: 62px;

  .information-container {
    display: flex;
    justify-content: center;
    align-items: stretch;
    margin-top: 28px;
    width: 100%;
    max-width: 1217px;
    align-self: center;

    .welcome-card {
      display: flex;
      flex: 2;
      border-radius: 18px;
      box-shadow: 0 2px 14px 0 rgba(196, 191, 191, 0.5);
      .left-column {
        display: flex;
        flex: 2;
        flex-direction: column;
        justify-content: space-between;
        padding: 32px 29px 32px 48px;
        border-radius: 18px 0 0 18px;
        background-image: linear-gradient(to bottom, #3ea8b0, $nexus-secondary-green);

        .title {
          font-family: $font-CoconPro;
          font-size: 30px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #ffffff;
        }

        .paragraph {
          font-family: $font-OpenSans;
          font-size: 16px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #ffffff;
          margin-top: 12px;
          margin-bottom: 0;
        }

        .button {
          margin-top: 32px;
          border: none;
        }
      }

      .right-column {
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
        border-radius: 0 18px 18px 0;
        background-color: #ffffff;
        min-width: 320px;

        img {
          border-radius: 0 18px 18px 0;
          min-width: 280px;
        }
      }
    }

    .news-card {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: space-between;
      opacity: 0.9;
      // todo review the image, because is not occupy the whole space
      background-size: cover;
      background: url("../../../assets/images/dashboard-background-card.png") round;
      padding: 32px 25px 29px 25px;
      margin: -10px -10px -14px 10px;

      img {
        width: 97px;
        height: 51px;
      }

      .title {
        text-shadow: 0 2px 13px #015e66;
        font-family: $font-OpenSans;
        font-size: 30px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.03;
        letter-spacing: -0.86px;
        color: #ffffff;
        margin-top: 15px;
        margin-right: 150px;
      }

      .buttons {
        display: flex;
        justify-content: center;
        .button {
          margin-top: 26px;
          border: none;
          margin-right: 5px;
          margin-left: 5px;
        }
      }
    }
  }

  .screen-links-container {
    display: flex;
    justify-content: center;
    margin: 12px 0px;

    :first-child.screen-quick-access-card {
      margin-right: 38px;
    }
  }
}
