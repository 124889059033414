.nexus-table {
    > .custom-table-loader {
        svg {
            fill: #2cb095;
            height: 80px;
            width: 80px;
            margin-top: -64px;
        }
    }
}
