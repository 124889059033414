@import '../../../styles/styles.scss';

.monetary-base-screen {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 10% 2% 10%;
}

.back-link {
  margin: 45px 0 25px 0;
}

.monetary-base-card {
  height: 100%;
  width: 100%;
  display: block;
  // overflow: auto;
  flex-direction: column
}

.header-card {
  height: 12%;
  width: 100%;
  display: flex;
  padding: 0 6% 0 3%;
  align-items: center;
  justify-content: flex-start;
  border-radius: 21px;
  box-shadow: 0 4px 20px 0 rgba(44, 176, 149, 0.25);
  border: solid 1px #dfe2e6;
  background-color: $nexus-secondary-green;
}

.monetary-base-card-head {
  height: 5%;
}

.title-bar-container {
  width: max-content;
  display: block;
  border-bottom: 2px solid $light-gray-fill;

}

.wrapper-card-monetary-base {
  overflow: hidden;
  padding-bottom: 40px;
}

.monetary-base-card-body {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 2% 11% 11% 11%;
}

.total-nex-card {
  width: 100%;
  height: 15%;
  padding: 2% 4% 2% 4%;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background-color: rgba(5, 122, 131, 0.12);
}

.total-nex-title {
  font-family: $font-Comfortaa;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 2px;
  color: $title-color;
  text-transform: uppercase;
}

.mint-burn-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 4% 0 3% 0;

  .padding-container {
    width: 100%;
    padding-right: 20px
  }
}

.notication-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fullscreen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  /* Asegúrate de que el valor del índice z sea superior a otros elementos */
  background-color: rgba(0, 0, 0, 0.5);
  /* Color de fondo oscuro */
}