@import "../../../styles/main.scss";
.success-account-creation-modal {
  .modal {
    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 74px 140px 74px 140px;
      margin-right: 10px;
      margin-left: 10px;
      i {
        font-size: 54px;
        color: $icon-success;
      }
      .title {
        font-family: $font-Comfortaa;
        font-size: 24px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.42;
        letter-spacing: normal;
        text-align: center;
        color: $title-color;
        margin-top: 15px;
        margin-bottom: 15px;
      }
    }
  }
  @media (max-width: 800px) {
    .modal {
      .content {
        padding: 74px 50px 74px 50px;
      }
    }
  }
}