.os-dashboard {
    width: calc(100vw - 105px);
    padding: 32px;
    display: flex;
    flex: 1;
    flex-flow: column nowrap;

    > .cards-container {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        gap: 32px;
        flex: 0;
        width: 100%;
        margin-bottom: 32px;
        color: #008e99;

        > .dashboard-card {
            display: flex;
            flex-flow: column nowrap;
            align-items: center;
            flex: 1;
            background-color: white;
            padding: 16px 0;
            border-radius: 16px;
            white-space: nowrap;

            > h1 {
                margin: 8px;
            }

            > h3 {
                font-family: Comfortaa, sans-serif;
                font-weight: 900;
                margin: 8px;
                margin-top: 16px;
            }
        }
    }

    > .graphs-container {
        background-color: white;
        padding: 16px;
        border-radius: 16px;
        display: flex;
        flex-flow: column nowrap;
        flex: 0;

        > .filters {
            padding-right: 16px;
        }

        > .bottom-row {
            display: flex;
            flex: 1;
            flex-flow: row nowrap;
            width: 100%;
            padding: 8px;

            > .graph-and-title {
                width: 100%;

                > .treemap-graphic,
                > .bar-graphic {
                    display: flex;
                    flex: 1;
                }

                > .treemap-graphic {
                    .apexcharts-data-labels {
                        transform: rotate(0deg) !important;
                    }
                }
            }
        }
    }

    > .benefits-container {
        background-color: white;
        padding: 16px;
        border-radius: 16px;
        display: flex;
        flex-flow: column nowrap;
        flex: 0;
        margin-top: 24px;

        table.GreyTable-CustomBenefits {
            width: 100%;

            > thead > tr > th {
                text-align: center;
                background-color: #f2f2f2;
                padding: 0.5em 0;
                font-weight: 900;
                box-shadow: 0 0 3px 0 #0003;
                clip-path: inset(0px -15px 0px -15px);
            }

            tbody.GreyTable-CustomBenefits {
                text-align: center;
                // border-radius: 1.5em;
                box-shadow: 0 0 3px 0 #0005;

                > tr {
                    height: 2em;

                    &:nth-child(even) {
                        background-color: #f4f4f4;
                    }

                    > td {
                        border-right: 1px solid #0003;

                        > i {
                            font-weight: bold;
                            color: #0008;
                            user-select: none;

                            &:hover {
                                color: #12b89c;
                            }
                        }

                        padding: 0 0.5em;

                        &:nth-child(1) {
                            width: 10% !important;
                        }
                        &:nth-child(2) {
                            width: 10% !important;
                        }
                        &:nth-child(3) {
                            width: 5% !important;
                        }
                        &:nth-child(4) {
                            width: 18% !important;
                        }
                        &:nth-child(5) {
                            width: 5% !important;
                        }
                        &:last-child {
                            width: 10% !important;
                        }
                    }

                    &:first-child td:last-child {
                        border-right: none;
                    }
                }

                &:nth-child(2) {
                    // border-radius: 0 0 1.5em 1.5em;
                    box-shadow: 2px 2px 2px 0 #0001, -2px 2px 2px 0 #0001;
                }
            }
        }
    }
}
