.nexus-select {
    position: relative;
    width: 300px;
    font-family: 'Comfortaa';
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    flex: 0;
    cursor: pointer;
    user-select: none;

    > .value-container {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        border: solid 1px #dfe2e6;
        border-radius: 9999px;
        padding: 0 16px;
        outline: none;
        cursor: pointer;
        background-color: white;
        height: 38px;
        width: 300px;

        &.placeholder {
            color: #9e9e9e;
        }
    }

    > .select {
        transition: opacity ease 100ms;
        width: 280px;
        opacity: 0;
        pointer-events: none;
        position: absolute;
        background-color: white;
        border: solid 1px #dfe2e6;
        border-radius: 8px;
        border-top: solid 3px #0aa6b1;
        box-shadow: 3px 6px 10px 1px rgba(0, 0, 0, 0.1);
        top: 38px;
        left: 10px;
        z-index: 100;
        padding: 16px;
        max-height: 70vh;
        overflow-y: scroll;

        &.show {
            opacity: 1;
            pointer-events: all;
        }

        > .option {
            display: flex;
            align-items: center;
            min-height: 38px;
            cursor: pointer;
            padding: 4px 8px;
            border-radius: 8px;

            &:hover {
                background-color: #f1f2f3;
            }

            &.selected {
                color: white;
                background-color: #74c3c8;
            }
        }
    }

    > i.icon-arrow-down,
    i.icon-arrow-up {
        position: absolute;
        top: 14px;
        right: calc(16px + 38px);
        pointer-events: none;
    }

    > .cross {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 800;
        font-size: 18px;
        padding-top: 2px;
        height: 38px;
        width: 48px;
        border-radius: 9999px;
        margin-left: 4px;
        opacity: 0;

        &:hover {
            background-color: #fafafa;
        }

        &.show-cross {
            opacity: 1;
        }
    }
}
