@import "../../../styles/main.scss";

.transactions-error-modal {
  display: flex;
  flex-direction: column;
  margin: 24px 24px 70px 32px;
  max-height: 530px;

  .header {
    display: flex;
    flex-direction: row!important;
    justify-content: space-between!important;
    align-items: flex-start;
    padding-bottom: 0!important;

    .title {
      font-family: $font-Comfortaa!important;
      font-size: 28px!important;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.21;
      letter-spacing: normal;
      color: $nexus-secondary-green!important;
      margin-left: 0!important;
    }
    i {
      font-size: 20px!important;
      color: #323133!important;
      margin-bottom: 0!important;
    }

    i:hover {
      cursor: pointer;
    }
  }

  hr {
    width: 100%;
    border: solid 1px #ebebeb;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .subtitle {
    font-family: $font-OpenSans;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.7;
    letter-spacing: normal;
    color: $title-color;
  }

  .errors-list {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    margin-top: 24px;
    height: 60%;

    .error-row {
      display: flex;
      margin-bottom: 14px;
      i {
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $icon-error;
        margin-right: 22px;
      }

      .error-detail {
        font-family: $font-Comfortaa;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: -0.39px;
        color: #323133;
      }
    }
  }
}