@import './styles.scss';

body {
  display: flex;
  flex: 1;
  min-height: 100vh;
  background-color: $background;
}

#root {
  display: flex;
  flex: 1;
  background-color: $background;
}

.main-container {
  margin: 50px;
  background-color: rgba(0,0,0,0);
  display: flex;
  flex: 1;
  justify-content: center;
}

.clickable {
  cursor: pointer;
}

// fonts

h1,h2,h3,h4,h5,h6,button {
  font-family: $header-font;
}

h4 {
  letter-spacing: 0.04em;
}

label, li, p, .title {
  font-family: $header-font;
}

p {
  font-weight: 300;
}

label {
  font-weight: 300;
}

input, textarea {
  font-family: $text-font;
  font-size: 17px;
  min-width: 0;
  width: 100%;
}

a {
  cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.row {
  align-items: flex-start !important;
}

@media only screen and (max-width: 768px) {
  .header-right {
    border-left: 1px white solid;
  }
}

// Errors

.error-message {
  margin: 0;
  opacity: 0;
  color: rgb(255, 0, 0);

  &.show {
    opacity: 1;
    transition: opacity 500ms ease-in;
  }
}

.success-message {
  margin: 0;
  opacity: 0;

  &.show {
    opacity: 1;
    transition: opacity 500ms ease-in;
  }
}

.custom-loader {
  display: flex;
  justify-content: center;
  margin: 30px;

}

.empty {
  display: flex;
  justify-content: center;
  font-size: 18px;
}

.tiitle {
  opacity: 0.8;
  font-family: CoconPro, sans-serif;
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $title-color;
}

div._loading_overlay_wrapper {
  position: unset;
}
