/* -------------------- Grey Table -------------------- */

table.GreyTable {
    width: 100%;
    --border-radius: .5em;
    --background: #2cb095;
    --green2: #1a8d76;
}

table.GreyTable > thead > tr:first-child > th:first-child { border-radius: var(--border-radius) 0 0 0; }
table.GreyTable > thead > tr:first-child > th:last-child { border-radius: 0 var(--border-radius) 0 0; }
table.GreyTable > tbody > tr:last-child > td:first-child { border-radius: 0 0 0 var(--border-radius); }
table.GreyTable > tbody > tr:last-child > td:last-child { border-radius: 0 0 var(--border-radius) 0; }
table.GreyTable > tbody > tr:last-child { border-radius: var(--border-radius); }


table.GreyTable > thead > tr > th {
    text-align: center;
    background-color: #f2f2f2;
    padding: .5em 0;
    font-weight: 900;
}

table.GreyTable > tbody > tr {
    text-align: center;
    background-color: #fafafa;
    border-bottom: 3px solid #FFF;
}

table.GreyTable > tbody > tr > td{
    vertical-align: baseline;
    height: 2.6em;
    font-weight: 500;
}

table.GreyTable .action-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height: 100%;
}

table.GreyTable .action-container i {
    font-weight: bold;
    color: #000B;
    user-select: none;
}
table.GreyTable .action-container i:hover {color: var(--background);}
table.GreyTable .action-container i:active {color: var(--green2);}

table.GreyTable .action-pipe {
    height: 70%;
    width: 1px;
    background-color: #e5e5e5;
    display: inline-block;
    user-select: none;
}

/* -------------------- Green Table --------------------*/

table.GreenTable {
    width: 100%;
    --background: #2cb095;
    --green2: #1a8d76;
    box-shadow: 0 2px 2px 0px #9991;
}

table.GreenTable > thead > tr > th {
    text-align: center;
    background-color: var(--background);
    color: #FFF;
    padding: .5em 0;
    font-weight: 400;
}
table.GreenTable.secondary > thead > tr > th {
    background-color: var(--green2);
}

table.GreenTable > tbody > tr {
    text-align: center;
    background-color: #FFF;
    height: 3.45em;
}

table.GreenTable > tbody > tr > td{
    vertical-align: baseline;
    height: 2.6em;
    box-shadow: 1px 0 0 0 #efeeee;
    font-weight: 800;
    padding-bottom: .5em;
    vertical-align: middle;
}
table.GreenTable > tbody > tr:first-child > td{padding-top: .5em;}
table.GreenTable.secondary > tbody > tr > td{
    background-color: #f6f6f6;
    padding: 0 1em;
}

table.GreenTable > tbody > tr > td:last-child{
    box-shadow: none;
}

table.GreenTable .action-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height: 100%;
}

table.GreenTable .action-container i {
    font-weight: bold;
    color: #000B;
    user-select: none;
}
table.GreenTable .action-container i.disabled {
    color: #0005;
}
table.GreenTable .action-container i:hover {color: var(--background);}
table.GreenTable .action-container i:active {color: var(--green2);}

table.GreenTable .action-pipe {
    height: 55%;
    width: 1px;
    background-color: #e5e5e5;
    display: inline-block;
    user-select: none;
}

/* MULTIPLE SELECT OVERRIDE */

table.GreenTable >thead>tr>th .select-multiple-container{
    display: flex;
    justify-content: center;
}
table.GreenTable >thead>tr>th .select-multiple-container>.select-multiple {
    all: revert;
}
table.GreenTable >thead>tr>th .select-multiple-container>.select-multiple>.select-multiple-placeholder {
    color: #FFF;
    font-weight: 400;
    font-size: 11pt;
}

/* ERRORS */

table > tbody > tr > td.error-td{
    box-shadow: inset 0 0 5px 1px #e88;
}

/* GENERAL */
table.coloreven > tbody > tr:nth-child(even) {background-color: #f8f8f8;}
table.coloreven > tbody > tr:nth-child(odd) {background-color: #fff;}

table.colsep > tbody > tr > td {border-right: 1px solid #e5e5e5;}
table.colsep > tbody > tr > td:last-child {border-right: none;}

table > tbody > tr.disabled > td {background-color: #eee; color: #0005;}

.scrollable  {
    position: relative;
    overflow-y: scroll;
    max-height: 15em;
}
.scrollable > table > thead{
    position: sticky;
    z-index: 10;
    top: 0; left: 0; right: 0;
    width: 100%;
}


.scrollable::-webkit-scrollbar {
    width: 10px;
}

.scrollable::-webkit-scrollbar-track {
    padding: 2px 0;
    background-color: #eee;
    border-radius: 10px;
    margin-left: 10px;
}

.scrollable::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #ccc;
}

.scrollable > table {
    border-right: 10px solid #FFF;
}