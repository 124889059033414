@import "../../../styles/main.scss";

.i-container {
  width: 100%;
  padding: 3em;

  .card-custom {
    margin-bottom: 0;
  }

  .card-row {
    .cb-mt {
      margin-top: 1.5em;
      .align-button{
        margin-top:3%;
        height: 100%;
        display: flex;
        align-items: center;
      }
    }

    .input-container {
      height: 47px;

      .input {
        font-family: 'Comfortaa';
        font-size: 14px;
        font-weight: 800;
      }
    }

    .checkbox-container .checkbox-label {
      font-size: 14px;
    }
  }

  .card-body-custom {
    padding: 0;
  }

  .custom-select-container .custom-select {
    height: 47px;
    width: 100%;
    padding: 10px 5px 10px 5px;
    border: solid 1px #dfe2e6;
    border-radius: 4px;
    background-color: #ffffff;

    .custom-select-value {
      font-size: 14px;
    }
  }
}

.section-detail-contract {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  box-shadow: 0 2px 17px 0 rgba(200, 200, 200, 0.5);
  background-color: #ffffff;
  font-family: 'Comfortaa';
  margin-bottom: 2em;
  background-color: res;

  .sample {
    background-color: #2cb095;
    color: #fff;

    font-family: Comfortaa;
    font-size: 18px;
    font-weight: 600;
  }

 .courier{
   background-color: #2cb095;
   color: #fff;
   align-items: flex-start !important;
   font-family: Comfortaa, serif;
   font-size: 18px;
   font-weight: 600;
  }

  .contract {
    background-color: #DFDFDF;
  }

  .header-version {
    width: 100%;
    height: 4em;
    border-radius: 16px 16px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2em;
    font-size: 15pt;

    .sm {
      width: 5em;
    }

    .hv-nr-btn {
      display: flex;
      align-items: center;
    }

    .hv-padd {
      padding: .5em;
    }
  }

  .body-version {
    width: 100%;
    padding: 3em 4em;

    .il-label-aud {
      margin-left: 4.3em;
      margin-bottom: 1.7em;
    }

    .il-chb {
      display: flex;
      justify-content: space-around;
    }

    .cd-head-bf {
      display: flex;
      justify-content: space-around;
      align-items: center;
      background: #EEEEEE;
      padding: .5em 0 .5em 0;

    }

    .add-app {
      color: #12b89c;
      font-weight: 700;
      text-decoration: underline;
      float: right;
    }

    .hr-add-pp {
      margin-top: 2em;
      margin-bottom: 0;
    }

    table.GreyTable-CustomBenefits {
      width: 100%;

      >thead>tr>th {
        text-align: center;
        background-color: #f2f2f2;
        padding: .5em 0;
        font-weight: 900;
        box-shadow: 0 0 3px 0 #0003;
        clip-path: inset(0px -15px 0px -15px);
      }

      tbody.GreyTable-CustomBenefits {
        text-align: center;
        border-radius: 1.5em;
        box-shadow: 0 0 3px 0 #0005;

        >tr {
          height: 2em;

          &:nth-child(even) {
            background-color: #f4f4f4;
          }

          >td {
            border-right: 1px solid #0003;

            >i {
              font-weight: bold;
              color: #0008;
              user-select: none;

              &:hover {
                color: #12b89c;
              }
            }

            padding: 0 .5em;

            &:nth-child(1),
            &:nth-child(2) {
              width: 5% !important;
            }

            &:nth-child(3),
            &:nth-child(4) {
              width: 18% !important;
            }

            &:nth-child(5) {
              width: 10% !important;
            }

            &:last-child {
              width: 5% !important;
            }
          }

          &:first-child td:last-child {
            border-right: none;
          }
        }

        &:nth-child(2) {
          border-radius: 0 0 1.5em 1.5em;
          box-shadow: 2px 2px 2px 0 #0001, -2px 2px 2px 0 #0001;
        }
      }
    }

    .cd-bf-item {
      white-space: pre-line;
    }

    .vl {
      border-left: 2px solid #F2F2F2;
    }

    .bv-dp {
      display: flex;
      justify-content: space-between;

      &.dl-mwmc {
        >div {
          margin-left: 1em;

          label.input-label {
            min-width: max-content;
          }

          &:first-child {
            margin-left: 0em;
          }
        }
      }

      &.bv-dp-even {
        justify-content: space-between;

        .bv-display {
          width: 100%;
          justify-content: space-around;
        }
      }
    }

    .bv-status {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      margin-bottom: 2em;

      .label-status {
        width: 60px;
        height: 18px;
        margin: 1px 51px 10px 51px;
        font-family: Comfortaa;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #383838;
      }

      .bv-btn-status {
        border-radius: 1em;
        padding: .3em 2em .3em 2em;
        background-color: #e0e0e0;
        border: .5px solid #afafaf;

        &.status-A {
          background-color: #D1EFE2;
          border: .5px solid #72CEA5;
        }

        &.status-B {
          background-color: #efd1d1;
          border: .5px solid #ce7272;
        }
      }
    }

    .bv-display {
      display: flex;
      align-items: center;
      font-size: 12pt;
    }

    .ip-custom-mx {
      width: 5.6em;
      margin-left: 0;
    }

    .ip-custom {
      width: 5.6em;
      margin-left: 2em;
    }

    label .sub-label {
      color: #12b89c;
      font-size: 12px;
    }

    /*.react-datepicker-wrapper { // TODO - CHECK IF MOVE TO CARD WORKS
      height: 42px;
      border-radius: 7px;
      .react-datepicker__input-container input {
        font-size: 14px;
      }
    }*/
  }
}

.width-20 {
  width: 20% !important;
}


// INDEPENDENT COMPONENTS
/* TODO - CHECK IF MOVE TO CARD WORKS
.error-next-datepicker + .react-datepicker-wrapper {
  border: 1px solid $icon-error!important;
}
*/