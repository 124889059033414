@import "../../../styles/main.scss";

.checkbox-container {
  display: flex;
  align-items: center;

  &.centered{ justify-content: center;}
  &.wallet{ margin-right: 10px}
  .checkbox-label {
    margin-right: 10px;
    margin-left: 10px;
    font-family: $font-Comfortaa;
    font-size: 16px;
    font-weight: 600;
    color: $title-color;
  }

  .regular-checkbox {
    -webkit-appearance: none;
    background-color: #fafafa;
    border: 1px solid #cacece;
    padding: 10px !important;
    border-radius: 3px;
    display: inline-block;
    position: relative;
    margin-top: 0;
    width: auto;

    &.disabled {
      border: solid 1px #d7d7d7;
      background-color: #d8d8d8;

    }

    &.checkboxes-row{
      border: 0;
      margin-right: 20px;
      font-size: 16px;

    }
  }

  .regular-checkbox:hover {
    cursor: pointer;
  }

  .regular-checkbox:focus {
    outline: none!important;
  }

  .regular-checkbox:active, .regular-checkbox:checked:active {
    box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px 1px 3px rgba(0,0,0,0.1);
  }

  .regular-checkbox:checked {
    background-color: #e9ecee;
    border: 1px solid #adb8c0;
    box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05), inset 15px 10px -12px rgba(255,255,255,0.1);
    color: #99a1a7;
  }

  .regular-checkbox:checked:after {
    content: '\2713';
    font-size: 14px;
    position: absolute;
    left: 5px;
    top: 0;
    color: #77b980;
  }

  &.pill-toggle{
    .regular-checkbox{
      height: 2.5em;
      width: 6em;
      border-radius: 2.5em;
      &::after {
        content: '';
        width: 1.95em;
        height: 1.95em;
        position:absolute;
        top: .25em;left: .25em;
        border-radius: 50%;
        background-color: #f4f4f4;
        box-shadow: 0 0 4px 1px #0006;
        top: .25em;right: .25em;left: auto;
      }
      &:checked {
        background-color: $icon-success;
        &:after {
          width: 2.4em;
          height: 2.4em;
          top: .25em;left: .25em;
        }
      }
    }
    &.small .regular-checkbox{
      height: 1.5em;
      width: 3em;
      &::after {
        width: 1.1em;
        height: 1.1em;
        top: .15em;right: .25em;left: auto;
        box-shadow: 0 0 2px 1px #0006;
      }
      &:checked {
        background-color: $icon-success;
        &:after {
          width: 1.25em;
          height: 1.25em;
          top: .22em;left: .25em;
        }
      }
    }
  }
}