// TODO
// Import from INDICATIONS

@keyframes editable {
    50% {
        border-bottom: 1px solid #2228;
    }
}

.btn-close {
    display: flex;
    justify-content: end;
    margin-bottom: 10px;
    cursor: pointer;
}

.label-comment {
    flex-direction: column;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 130px;
    padding: 2px;

    .date-comment-adj {
        padding: 2px;
        color: #888;
    }
}

.i-container {
    .temp-input {
        color: #000;

        &.simple-text-input {
            height: auto !important;
            width: auto;

            padding: .5em;
            margin: 0em -.5em;

            /*
            border: none;
            border-bottom: 1px solid #2220;
            border-radius: 0;
            box-shadow: none;
            animation: editable .8s step-start infinite;
            */


            >input {
                font-weight: 300 !important;
                text-align: left;
                width: 0;
            }

            >.react-datepicker-wrapper {
                height: auto;
                border: none !important;

                * {
                    font-weight: 300;
                    padding: 0;
                }
            }

            &.ti-date {
                width: 7em;
            }
        }

        &.file-input {
            position: relative;
            border: none;
            box-shadow: none;
            font-weight: 400;

            >input {
                display: none;
            }

            >span {
                display: flex;
                align-items: center;
                font-family: sans-serif;

                >i {
                    margin: 0 .6em 0 1em;

                    &.icon-paper-clip {
                        transform: rotate(316deg);
                    }
                }

                >div {
                    text-decoration: underline;
                    margin-right: 1em;
                }
            }

            >.file-container {
                height: auto;

                display: none;
                position: absolute;
                top: 1.5em;
                right: 0;
                padding: 1em;
                min-width: 100%;
                width: max-content;
                border-radius: 4px;
                z-index: 999;

                font-family: sans-serif;

                background-color: #fff;
                box-shadow: 0 0 3px 1px #0002;

                >.file-item {
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    background-color: #f2f2f2;
                    padding: .5em;
                    margin-bottom: 1em;
                    width: auto;
                    position: relative;

                    >div {
                        display: flex;

                        &:nth-child(1) {
                            color: rgb(53, 73, 187);
                            font-weight: 500;
                        }

                        &:nth-child(2) {
                            color: #888;
                            margin-left: .5em;
                        }

                        &:nth-child(3) {
                            position: absolute;
                            top: 5px;
                            right: 5px;
                            color: #888;
                        }
                    }
                }

                >._addFile {
                    float: right;
                    font-family: sans-serif;
                }

            }

            &.show-files>.file-container {
                display: block;
            }
        }
    }
}

.container-actions {
    display: flex;
    justify-content: flex-end;
    height: 100%;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
}

.green-attachment-btn {
    color: #53bfa9 !important;
}