@import '../../../styles/styles.scss';

.loader {
  display: flex;

  .text {
    text-align: center;
  }

  svg {
    fill: $nexus-primary-green;
    height: 80px;
    width: 80px;
  }
}