@import '../PatientEvidenceScreen/PatientEvidenceScreen.scss';
@import '../../../indication/components/Table/table.css';

table.dosesTable {
    width: 100%;

    thead{
        box-shadow: inset 0 0 0 40px #fff;/* Fills border */
        tr{

            th{ text-align: center; }
            &:first-child{

                border-radius: .5em;
                th{
                    &:first-child{
                        background-color: $nexus-secondary-green;
                        color: #FFF;
                        padding: .4em;
                        border-bottom: 5px solid #FFF;
                        border-top-left-radius: .5em;
                    }
                    &:nth-child(2){border-left: 5px solid #FFF;}
                    &:nth-child(2),
                    &:nth-child(3),
                    &:nth-child(4),
                    &:nth-child(5){
                        background-color: #d2eceb;
                    }
                    &:nth-child(5){  border-top-right-radius: .5em; }
                }

            }
            &:nth-child(2){background-color: #e6f1f1;}
        }
    }
    tbody{
        text-align: center;
        tr{
            td{
                border-right: 1px solid #0004;
                padding: .5em;
                &:last-child{border-right: none;}
                &:nth-child(5){
                    background-color: #f4f4f4;
                    border-left: 5px solid #FFF;
                    box-shadow: inset 1px 0px 0px 0px #0004;
                }
                &:nth-child(6),&:nth-child(7),&:nth-child(8){
                    background-color: #f4f4f4;
                }
            }
            th{
                text-align: center;
                background-color: #f2f2f2;
                padding: .5em 0;
                box-shadow: inset 0px 1px 0 0 #0003;
                border-bottom: 3px solid #FFF;

                &:first-child{
                    border-bottom-left-radius: .7em;
                }

                &:last-child{
                    color: crimson;
                    border-bottom-right-radius: .7em;
                }
            }
            &:hover > td {
                background-color: #d2eceb40;
            }
            &.selected > td {
                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(4){
                    background-color: #c0f0e455;
                    border-top: 1px solid $nexus-primary-green;
                    border-bottom: 1px solid $nexus-primary-green;
                }
                &:nth-child(1){
                    border-top-left-radius: .7em;
                    border-bottom-left-radius: .7em;
                    border-left: 1px solid $nexus-primary-green;
                }
                &:nth-child(4){
                    border-top-right-radius: .7em;
                    border-bottom-right-radius: .7em;
                    border-right: 1px solid $nexus-primary-green;
                }
            }
        }

    }
}
.error-text {
  background-color: $nexus-secondary-green;
  color: #FFF;
  font-size: 16px;
  font-weight: bold;
}
