@import '../../../../styles/main.scss';

.OSAudit-detail-modal {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    width: 90rem;
    min-height: 60rem;
    background-color: $gray;
    border-radius: 8px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    z-index: 999;

    hr {
        width: 80%;
        border-color: $disabled;
    }

    .bold {
        font-weight: 600;
    }

    .OSAudit-colorbar-detail {
        display: grid;
        grid-template-columns: 46% 28% 26%;
        width: 100%;
        color: $gray;
        letter-spacing: 1px;
        border-top-left-radius: 18px;
        border-top-right-radius: 18px;

        .OSAudit-colorbar-patient {
            display: flex;
            justify-content: space-around;
            align-items: center;
        }

        .box {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &.auditApproved {
            background: linear-gradient(
                0deg,
                $nexus-secondary-green 10%,
                $nexus-primary-green 100%
            );
            height: 4rem;
        }
        &.auditPending {
            background: linear-gradient(0deg, #b18f4b, #d0a754);
            height: 4rem;
        }
        &.auditRejected {
            background: linear-gradient(0deg, rgba(170, 59, 65, 1) 35%, rgba(201, 88, 87, 1) 100%);
            height: 4rem;
        }
        &.auditRequired {
            background: linear-gradient(
                0deg,
                rgba(78, 154, 202, 1) 35%,
                rgba(84, 160, 208, 1) 100%
            );
            height: 4rem;
        }
        &.auditConsumptionRejected {
            background: linear-gradient(
                0deg,
                rgba(78, 154, 202, 1) 35%,
                rgba(84, 160, 208, 1) 100%
            );
            height: 4rem;
        }

        .OSAudit-colorbar-circle {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 2.5rem;
            height: 2.5rem;
            border: 1px solid white;
            border-radius: 100%;
            padding-left: 1px;
            margin: 0 2rem;
        }

        &.number {
            margin: 0 1rem;
        }
    }

    i {
        color: $icon-success;
        font-size: 54px;
    }

    .OSAudit-header {
        padding-top: 2rem;
        width: 100%;
        height: 6rem;
        display: grid;
        grid-template-columns: 30% 35% 20% 10%;
        grid-gap: 10px;

        .OSAudit-box {
            height: auto;
            display: flex;
            justify-content: center;
            align-items: center;

            :nth-child(1) {
                margin-right: 1rem;
            }
        }
    }

    .modal-body-text {
        font-family: $font-Comfortaa;
        font-size: 24px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.42;
        letter-spacing: normal;
        text-align: center;
        color: $title-color;
        margin-top: 21px;
    }

    .OSAudit-auditgroup {
        width: 100%;
        display: grid;
        grid-template-columns: 50% 50%;
        padding: 0 5rem;

        .box {
            display: flex;
            align-items: center;
            height: 5rem;
            padding-bottom: 1rem;
        }

        :nth-child(1) {
            justify-content: flex-start;
        }
        :nth-child(2) {
            justify-content: flex-end;
        }
        :nth-child(3) {
            grid-column: 1 / 3;
            grid-row: 2;
        }

        .OSAudit-reportbutton {
            width: 20rem;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 15px;
            background: none;
            color: $nexus-primary-green;
            border: solid 1px $nexus-primary-green;
            height: 3rem;
            font-family: $font-Comfortaa;
            font-weight: 700;
            transition-duration: 200ms;
            transition-timing-function: ease-in-out;

            &:hover {
                cursor: pointer;
                color: $nexus-secondary-green;
                border-color: $nexus-secondary-green;
            }
        }
    }

    .body-title {
        font-size: larger;
        font-weight: 700;
        margin-bottom: 8px;
    }

    .OSAudit-commentsection {
        min-height: 20rem;
        width: 100%;
        padding: 0 5rem;
        display: flex;
        flex-direction: column;
        gap: 8px;

        :nth-child(2) {
            height: 15rem;
        }

        .txtarea-auditrejected {
            border-radius: 6px;
            border: 2px solid #c95857;
        }

        .addcomment-button {
            height: 3rem;
            position: absolute;
            bottom: 3rem;
            right: 2rem;
            float: right;
            background: none;
            border: none;
            color: $nexus-primary-green;
            font-family: $font-Comfortaa;
            font-weight: 700;
            text-decoration: underline;
            transition-duration: 200ms;
            transition-timing-function: ease-in-out;
        }
        .addcomment-button:hover {
            color: $nexus-secondary-green;
        }
        .addcomment-button:focus {
            border: none;
        }
    }

    .OSAudit-footer {
        width: 100%;
        height: 10rem;
        padding: 0 5rem;
        display: flex;

        align-items: center;

        &.auditApproved {
            justify-content: flex-end;
        }
        &.auditConsumptionRejected {
            justify-content: flex-end;
        }
        &.auditRequired {
            justify-content: center;
        }
        &.auditPending {
            justify-content: flex-end;
        }
        &.auditRejected {
            justify-content: space-between;
        }

        .share {
            background: linear-gradient(0deg, rgb(43, 155, 132) 35%, rgba(44, 176, 149, 1) 100%);
            width: 100%;
            border-radius: 10px;
        }

        .back {
            border-radius: 10px;
            width: 15rem;

            &.gradient {
                background: linear-gradient(
                    0deg,
                    rgb(43, 155, 132) 35%,
                    rgba(44, 176, 149, 1) 100%
                );
            }
        }

        .resend {
            background: linear-gradient(0deg, rgb(43, 155, 132) 35%, rgba(44, 176, 149, 1) 100%);
            width: 100%;
            margin-right: 1rem;
            border-radius: 10px;
        }
    }
}
