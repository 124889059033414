@import "../../../styles/styles.scss";

.modal-backdrop {
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.14);
}
.modal {
  &.show {
    display: flex!important;
  }

  display: none;
  justify-content: center;
  align-items: center;
  z-index: 999;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.4);

  &.no-zindex {
    z-index: 10;
  }

  // todo the fix width need to be change to auto, but first all modal must be checked
  .content {
    display: flex;
    flex-direction: column;
    width: 805px;
    height: auto;
    padding-right: 38px;
    padding-left: 37px;
    border-radius: 8px;
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.5);
    background-color: #ffffff;

    &.auto {
      width: auto;
    }

    &.no-padding {
      padding: 0;
    }

    &.lg-radius-top {
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
    }

    .header {
      display: flex;
      justify-content: flex-start;
      background-color: transparent;
      margin: 0;
      padding-top: 35px;
      padding-bottom: 24px;

      i {
        -webkit-text-stroke: 1px $nexus-primary-green;
        font-size: 30px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $nexus-primary-green;
      }

      .title {
        font-family: $font-Comfortaa;
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.69px;
        color: $nexus-secondary-green;
        margin-left: 15px;
        &.center-title{
        width: 100%;
        text-align: center;
        }
      }
    }

    .separator-line {
      width: 100%;
      height: 1px;
      border: solid 1px #dbdbdb;
    }

    .body {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;
      padding-left: 24px;
      padding-right: 27px;
    }

    .modal-body-label{
      font-family: $font-Comfortaa;
      display: flex;
      flex-direction: column;
      justify-content: center;
        padding-left: 16px;
      padding-bottom:2px;
      font-size: 16px;
    }

    .footer {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 21px;
      margin-bottom: 45px;

      .button {
        opacity: 0.82;
        height: 40px;
        width: 150px;
        margin-left: 13px;
      }
    }
  }
}
