@import "../../../styles/main.scss";

.product-card {
  display: flex;
  justify-content: space-between;
  width: 320px;
  border-radius: 4px;
  background-color: #f3f3f3;
  padding: 18px 16px 60px 20px;
  position: relative;

  .labels {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: right;

    .label {
      font-family: $font-OpenSans;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: right;
      color: #344344;
      &.primary {
        color: $nexus-primary-green;
      }
    }
  }

  .inputs {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 150px;

    input {
      background-color: transparent;
      border: none;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      &:focus{
        outline: none;
      }
      &:hover {
        overflow: visible;
      }
    }

    .select {
      margin-right: 5px;
      border-radius: 4px;
    }
  }

  i {
    font-size: 20px;
    color: #131415;
  }

  i:hover {
    cursor: pointer;
  }

  button.delete-product-button {
    height: 20%;
    padding: 0;
    border: none;
    background: none;
  }

  button.add-product-button {
    position: absolute;
    bottom: 0;
    margin-left: 21%;
    margin-bottom: 3%;
  }

  @media (max-width: 992px) {
    width: 280px;
    padding: 18px 8px 18px 8px;
  }

  &.active {
    background-color: #c0f0e455;
    border: 1px solid $nexus-primary-green;
  }
}
