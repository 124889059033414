.wallet-screen-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 10vw 5vh 10vw;
}

.wallet-card-container {
  margin: 0;
  width: 85%;
  height: 100%;
  min-width: 800px;
  display: block;
  overflow: auto;
}

.wrapper-notification {
  height: 30px;
  min-width: 600px;
  padding-top: 7px;

  .container-notification {
    width: 100%;

    .maxContainer-notification {
      max-width: 800px;
      padding: 10px;
    }
  }

}