@import "../../../styles/main";

.body {
    width: 100%;
    padding: 3em;

    .body-children {
        width: 100%;
        
        .divider-funder {
            width: 100%;
            height: auto;
            border: solid 2px hsl(0, 0%, 95%);
        }

        .coverage-row {
            display: flex;
            flex-direction: row;
            grid-gap: 10px;
            justify-content: flex-start;
            align-items: baseline;
            justify-items: center;

            .item {
                width: 85px;
                height: 45px;
                align-content: center;
                justify-content: start;

                .percentage {
                    font-family: Comfortaa;
                    font-size: 20px;
                    font-weight: 600;
                    text-align: left;
                    color: #011415;
                }
            }
        }
    }
}
.margin-right {
    margin-right: 5px;
}