body {
  display: flex;
  flex: 1;
  min-height: 100vh;
  background-color: #f0f0f0; }

#root {
  display: flex;
  flex: 1;
  background-color: #f0f0f0; }

.main-container {
  margin: 50px;
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  flex: 1;
  justify-content: center; }

.clickable {
  cursor: pointer; }

h1, h2, h3, h4, h5, h6, button {
  font-family: "CoconPro", sans-serif; }

h4 {
  letter-spacing: 0.04em; }

label, li, p, .title {
  font-family: "CoconPro", sans-serif; }

p {
  font-weight: 300; }

label {
  font-weight: 300; }

input, textarea {
  font-family: "Roboto", serif;
  font-size: 17px;
  min-width: 0;
  width: 100%; }

a {
  cursor: pointer; }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */ }

.row {
  align-items: flex-start !important; }

@media only screen and (max-width: 768px) {
  .header-right {
    border-left: 1px white solid; } }

.error-message {
  margin: 0;
  opacity: 0;
  color: red; }
  .error-message.show {
    opacity: 1;
    transition: opacity 500ms ease-in; }

.success-message {
  margin: 0;
  opacity: 0; }
  .success-message.show {
    opacity: 1;
    transition: opacity 500ms ease-in; }

.custom-loader {
  display: flex;
  justify-content: center;
  margin: 30px; }

.empty {
  display: flex;
  justify-content: center;
  font-size: 18px; }

.tiitle {
  opacity: 0.8;
  font-family: CoconPro, sans-serif;
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #011415; }

div._loading_overlay_wrapper {
  position: unset; }

.TextArea {
  margin-bottom: 1em;
  position: relative;}

.TextArea > textarea {
  resize: vertical;
  padding: 1.7em 1em 1em 1em;
  border: 1px solid #e2e5e9;
  border-radius: 0.3em;
  height: auto;
  color: #000b;
 }
 .TextArea.height > textarea {
  height: 70px; }

.TextArea.extra-padding-top > textarea {
  padding-top: 3em; }

.TextArea.error > textarea {
  border: 1px solid #c95857; }

.TextArea > .txta-label {
  position: absolute;
  left: 1em;
  top: 0.5em;
  font-weight: 600;
  font-size: 10.5pt;
  font-family: "Roboto";
 }

  .TextArea > .txt-label {
    position: absolute;
    left: 1em;
    top: 0.5em;
    font-weight: 600;
    font-family: OpenSans;
    font-size: 10.5pt;
    font-style: italic;
    text-align: left;
    color: #6e7374;
   }
  

.auditrejected-button {
  height: 2rem;
  width: 20rem;
  margin: 0.3rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(201, 88, 87, 0.2);
  color: #c95857;
  border: solid 1px #c95857;
  border-radius: 15px;
  font-family: "Comfortaa";
  font-weight: 700; }

.comment-textarea > textarea {
  border: 1px solid rgba(40, 40, 40, 0.2);
  border-radius: 15px;
  background-color: white; }

.label-mt > .txta-label {
  margin-top: 1em;
  margin-left: 0.5em;
  font-size: 16px; }
