.auditor-monitor-screen {
    height: 90%;
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    margin: 32px;
    padding: 32px;
    border-radius: 16px;

    > .header {
        background-color: unset;
        margin-bottom: 32px;

        > .title {
            margin-right: 13px;
            font-size: 26px;
            font-family: Comfortaa, sans-serif;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: -0.75px;
            color: black;
        }
    }
}
