@import "../../../styles/main.scss";

.profile-configuration-card {
  display: flex;
  flex-direction: column;
  padding: 39px 39px 54px 39px;
  border-radius: 7px;
  box-shadow: 0 2px 4px 0 rgba(228, 228, 228, 0.5);
  background-color: #ffffff;
  max-width: 754px;

  .active-key-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;
    border-radius: 5px;
    border: solid 1px #58beab;
    background-color: #d1ece6;
    margin-bottom: 23px;

    i {
      -webkit-text-stroke: 1px #055f62;
      font-size: 20px;
      color: #0e9997;
      margin-right: 10px;
    }
    span {
      font-family: $font-Comfortaa;
      font-size: 16px;
      font-weight: bold;
      color: #055f62;
    }
  }

  .profile-configuration-title {
    font-family: $font-CoconPro;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4a4a4a;
    margin-bottom: 41px;
  }

  hr {
    margin: 0;
    width: 100%;
    border: solid 1px #f3f3f3;
  }

  .profile-configuration-user-form {
    display: flex;
    flex-direction: column;
    padding-top: 34px;

    .fields-container {
      display: flex;

      .left-column {
        margin-right: 29px;
      }

      .right-column {
        margin-right: 9px;
      }
    }

    .button {
      margin-bottom: 24px;
      height: 41px;
      align-self: center;
    }

  }

  .create-private-key-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 87px;
    margin-top: 70px;
    border-radius: 7px;
    background-color: $background-color;

    &:hover {
      cursor: pointer;
    }

    i {
      font-size: 22px;
      color: #079398;
    }

    .text {
      font-family: $font-Comfortaa;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #079398;
      text-decoration: underline;
      margin-left: 7px;
    }
  }

  .private-key-buttons-container {
    display: flex;

    .private-key-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 87px;
      margin-top: 48px;
      border-radius: 7px;
      background-color: white;
      border: solid 1px #22a996;
      margin-left: 12px;

      &:hover {
        cursor: pointer;
      }

      i {
        font-size: 22px;
        color: #079398;
      }

      .text {
        font-family: $font-Comfortaa;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #079398;
        text-decoration: underline;
        margin-left: 7px;
      }

      &.fill {
        margin-right: 12px;
        margin-left: 0;
        background-image: linear-gradient(to bottom, #28ad95, #059299);

        i, .text {
          color: white!important;
        }
      }
    }
  }
}

.show-key-container {
  display: flex;
  align-items: center;
  margin-top: 18px;
  margin-bottom: 20px;

  i {
    -webkit-text-stroke: 1px #656565;
    font-size: 18px;
    color: #656565;
    margin-right: 9px;
  }

  span {
    font-family: $font-Comfortaa;
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-decoration: underline;
    color: #5a5a5a;
  }

  span:hover{
    cursor: pointer;
  }
}