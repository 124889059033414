@import "../../../styles/main.scss";

.migration-modal {
  .footer {
    margin-left: 24px;
    flex-direction: column;
    gap: 16px;
  }

  .error-container {
    margin-top: 8px;
    white-space: pre;
    .error-stack-container {
      margin-left: 16px;
      margin-top: 6px;
    }
  }
}
