@import "../../../styles/main.scss";
.registrar-sign-up-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  max-width: 904px;
  width: 100%;
  padding-right: 50px;
  padding-left: 50px;

  .title {
    opacity: 0.8;
    font-family: $font-CoconPro;
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $title-color;
    align-self: flex-start;
    margin-bottom: 50px;
  }

  .fix-height {
    height: 53px;
    width: 100%;
    margin-bottom: 12px;
  }

  .inputs-row {
    display: flex;
    width: 100%;
    margin-bottom: 30px;

    .input-container:last-child {
      margin-left: 22px;
    }
  }

  .button {
    margin-top: 70px;
  }

  @media (max-width: 688px) {
    .inputs-row {
      flex-direction: column;
      margin-bottom: 0;

      .input-container:last-child {
        margin-left: 0;
      }
    }

    .button {
      margin-top: 30px;
      margin-bottom: 20px;
    }
  }
}
