@import '../../../styles/main.scss';

.contracts-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-right: 81px;
  padding-left: 81px;
  background-color: $background-color;
  
  .header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: auto;
    width: 100%;
    margin-top: 55px;
    margin-bottom: 23px;
    align-self: center;
    background-color: $background;
    
    .title {
      display: flex;
      margin-right: 43px;
      font-size: 26px;
      font-family: $font-Comfortaa;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.75px;
      color: $font-main-color;
    }
    
    .search-button {
      display: flex;
      margin-left: 28px;
    }
  }

  .table-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}