@import "../../../styles/main.scss";

.patient-details-breadcrumbs {
    width: 90%;
    text-align: left;
    margin-top: 35px;
    color: #6e7f80;
    text-decoration: none;
    font-family: Comfortaa, serif;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.75px;

    &.margin-bottom{
        display: flex;
        margin-bottom: 15px;
    }
  }