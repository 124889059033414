@import '../../../styles/main.scss';

.bonification-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-right: 81px;
    padding-left: 81px;
    background-color: $background-color;

    .header {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
        margin-bottom: 10px;
        background-color: transparent;

        .header-bar {
            display: flex;
            width: 100%;
            align-items: center;
            height: auto;
            margin-top: 40px;
            background-color: transparent;
            gap: 10px;
        }

        .title {
            margin-right: 13px;
            font-size: 26px;
            font-family: $font-Comfortaa;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: -0.75px;
            color: $font-main-color;
        }
        .download-button {
            height: 100%;

            i {
                font-size: 25px;
            }
        }
        .action-button-container {
            display: flex;
            justify-content: flex-end;
            gap: 8px;

            .modal-text-container {
                display: flex;
                justify-content: center;

                .modal-children-container {
                    display: flex;
                    flex-direction: column;;
                    gap: 20px;
                    width: 100%;

                    .observation-title {
                        font-size: 16px;
                        font-family: $font-OpenSans;
                        color: $font-main-color;
                    }

                    .observation-textarea {
                        width: 100%;
                        font-family: $font-OpenSans;
                        color: $font-main-color;
                    }

                    .modal-text {
                        font-size: 18px;
                        font-family: $font-OpenSans;
                        color: $font-main-color;
                        align-self: center;
                    }
                }
            }
        }
    }

    .header-checkbox {
        display: inline-flex;
        align-items: center;
        flex-direction: column;

        input {
            margin-left: 15px;
            margin-bottom: 5px;
        }
    }

    .checkbox-cell {
        margin-left: 10px;
        display: flex;
        justify-content: center;
    }

    .tooltip-cell-container {
        width: 100%;
        height: 100%;
        position: relative;
        &:hover {
            cursor: pointer;

            .edit-observation-icon {
                display: block;
            }
        }

        .edit-observation-icon {
            color: $icon-gray;
            font-size: 12px;
            position: absolute;
            left: 100%;
            top: -10%;
            display: none;
        }

        .bonification-tooltip {
            opacity: 1 !important;
            border-radius: 6px !important;
            box-shadow: 0 2px 11px 0 rgba(94, 94, 94, 0.1) !important;
            padding: 15px 10px !important;
            font-family: 'Open Sans', sans-serif !important;
            font-size: 14px !important;
            max-width: 400px !important;

            &.empty {
                padding: 30px 20px 30px 20px !important;
                color: $icon-gray;
            }
            &.paragraph {
                padding: 30px 20px 30px 20px !important;
                color: $font-main-color;
            }
        }
    }
}
