@import "../../../styles/main.scss";

.paged-drawer {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 13px 0;
  color: $light-text;

  span {
    font-family: $font-OpenSans;
    font-size: 15.2px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #b4bac6;
  }
}