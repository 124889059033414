@import '../../../../styles/styles.scss';
.wallet-key-container {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: 100%;
    min-height: 30px;
    border-radius: 7px;
    border: solid 1px $white;
}
.wallet-key {
    font-family: $font-Comfortaa;
    font-size: 1em;
    color: $white;
    width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
}
.copy-key-icon {
    transition: opacity 0.5s ease-in-out;
}

.image-link {
    display: inline-block;
    position: relative;
    margin-top: 2px;
}
.image-link:hover .highlight-image {
    opacity: 1;
}

.iconCheck {
    opacity: 0;
    transition: 0.5s;
}

.iconCheck.show {
    opacity: 1;
}
