@import '../../../styles/styles.scss';

.card-sendNex {
    display: flex;
    justify-content: center;
    overflow: hidden;
    padding: 3% 8% 3% 8%;
}

.container-sendNex {
    width: 480px;

    .input-wrapper-sendNex {
        padding-top: 20px;
    }

    .input-code {
        padding-bottom: 5px;
    }

    .input-import {
        padding-bottom: 25px;
    }

    .button-wrapper-error {
        display: flex;
        flex-direction: row;
        column-gap: 8px;
    }

    .wrapper-icon-notification {
        display: flex;
        flex-direction: row;
        column-gap: 8px;
    }

    .wapper-notification {
        display: flex;
        justify-content: center;
        padding: 0 0 18px 0;
    }

    .wapper-notification-transaction {
        padding: 0px 0 28px 0;
    }

    .btn-text {
        font-family: Comfortaa;
        font-size: 14px;
        color: $modal-color-white;

        .text-cancel {
            font-weight: 600;
        }

        .text-newTry {
            line-height: 1.07;
        }
    }
}

.container-icon {
    display: flex;
    justify-content: center;
    padding-bottom: 33px;

    .background-icon-shared {
        padding: 20px;
        border-radius: 60px;
        background-color: $prospect-color-light;
    }
}

.container-linkAccess {
    width: 100%;

    .linkAccess {
        display: flex;
        flex-direction: row;
        justify-content: center;

        .title-linkAccess {
            padding-left: 3.2px;
            font-family: Comfortaa;
            font-size: 14px;
            font-weight: 600;
            text-align: left;
            color: $green-increase;
        }
    }
}