@import '../../../../styles/styles.scss';

.grant-container{
  width: 100%;
  padding: 7px 0;
  height:45%;
  display: block;
}

.grant-list-container{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  margin: 0;
  overflow:auto;
  height: 40%;

  .wrapper-loader{
    width: 100%;
    display: flex;
    justify-content: center;
  }

}
.grant-list-container::-webkit-scrollbar {
  display: none;
}
.grant-card-container {
  width: 48%;
  height: 32%;
  margin: 0.5% 0 1.5% 0;
}
.paste-address-container{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 20px ;
  height: 30%;
  width: 100%;
  margin:1% 0 3.5% 0;
  border-radius: 7px;
  border: solid 1px $light-gray-fill;
}
.add-address-container{
  display: flex;
  height: 45%;
  width: 100%;
  flex-direction: column;
  align-items: center;
  .wrapper-container{
    width: 100%;
    margin: 0 0 15px 0;
  }
}
.card-key-text{
  width: 70%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: $font-Comfortaa;
  font-size: 14px;
  font-weight: bold;
  color: $title-color;
}
