@import "../../../styles/main.scss";

.change-password-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 40px 40px 40px;

  .fix-height {
    height: 50px;
    width: 100%;
    margin-bottom: 20px;
  }

  .title {
    font-family: $font-Comfortaa;
    font-size: 26px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.85;
    letter-spacing: -0.63px;
    text-align: center;
    color: #323133;
    margin-bottom: 20px;
  }

  .input-container {
    margin-bottom: 20px;
  }

  .buttons-container {
    display: flex;
    margin-top: 20px;

    .button:first-child {
      margin-right: 15px;
    }
  }
}