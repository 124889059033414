@import '../../../styles/styles.scss';

.body-link {
    width: 100%;
    padding-top: 4.05em; 

    .container-link {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        float: left;
        flex-wrap: nowrap;
        justify-content: flex-end;
    }
}

.link-access-text{
    font-family: $font-Comfortaa;
    font-size: 14px;
    font-weight: bold;
    color: $nexus-secondary-green;
    margin-left: 5px;
}