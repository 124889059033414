@import "../../../styles/main.scss";

.registrar-institution-form {
  display: flex;
  flex-direction: column;
  min-width: 468px;

  .error-box {
    margin-bottom: 16px;

    .error-msj {
      text-align: left;
    }
  }

  .title {
    font-family: $font-CoconPro;
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $title-color;
  }

  .subtitle {
    font-family: $font-OpenSans;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.1;
    letter-spacing: -0.48px;
    color: #323133;
    margin-top: 20px;
  }

  .input-container, .custom-select-container {
    margin-top: 32px;
    margin-bottom: 0;

    &.disabled {
      background-color: #eeeeee!important;
      .input {
        background-color: #eeeeee!important;
      }
    }

    i {
      -webkit-text-stroke: 1px #979797;
    }
  }

  hr {
    width: 100%;
    border: dashed 1px #979797;
    margin-top: 36px;
  }

  .button {
    margin-top: 56px;
  }

  .log-out-text {
    font-family: $font-Comfortaa;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: normal;
    color: #323133;
    text-decoration: underline;
    margin-top: 15px;
    margin-left: 10px;

    &:hover {
      cursor: pointer;
    }
  }

  @media (max-width: 480px) {
    min-width: unset;
    width: 100%;
    margin-right: 20px;
    margin-left: 20px;
    margin-bottom: 20px;
  }
}