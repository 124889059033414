// ----- GENERAL -----

.i-container{
    width: 100%;
    padding: 3em;
}

.f-right {
    float: right;
}

// ----- INDEPENDENT COMPONENTS -----

.temp-input{
    text-align: center;
    border-radius: 8px;
    border: 1px solid #e2e5e9;
    box-shadow: 0 0 3px 0 #0001;
    &.is-small{
        height: 2em;
        input{
            font-size: 10pt;
            font-weight: 800;
            text-align: center;
            padding: 0;
            font-family: 'Comfortaa';
        }
    }
    &.input-container{
        margin: 0;
    }
}

.detailedLabel{

    position: relative;
    border: 1px solid #e2e5e9;
    box-shadow: 0 0 3px 0 #0001;
    border-radius: 7px;
    .dlabel-text {
        padding: .5em;
        font-size: 10pt;
        height: 2.5em;
        line-height: 1.75em;
        overflow: hidden;
    }

    .detail{
        opacity: 0;
        display: none;
        position: absolute;
        right: 0;
        top: 2.5em;
        padding: 1em 3em 1em 1em;
        border-radius: 4px;
        border: 1px solid #e2e5e9;
        box-shadow: 0 0 3px 0 #0001;
        background-color: #FFF;
        z-index: 500;
        width: 60%;
    }
    &:hover .detail{
        opacity: 1;
        display: block;
    }
}

.IndStatusModal {
    padding: 2em;
    display: flex;
    align-items: center;
}

// ----- TABLES -----

.ind-det-card .input-container input.input,
.ind-det-card .custom-select-container {
    font-size: 16px;
    font-family: 'Comfortaa';
    font-weight: 600;
}
.TreatmentTable .custom-select-container>.custom-select-options-container {
    width: max-content;
}

// Treatment (Grey)
table.TreatmentTable > tbody > tr > td {
    width: 13.5%!important;
    padding: 0 1em;
    &:nth-child(1),&:nth-child(2){width: 6%!important;}
    &:last-child{ min-width: 6em; }
}
table.TreatmentTable > thead > tr > th {
    width: 13.5%!important;
    padding: 0 1em;
    &:nth-child(1){width: 12%!important;}
    &:last-child{ min-width: 6em; }
}

// Evidences (Green)
table.EvidencesTable > tbody > tr > td,
table.EvidencesTable > thead > tr > th {
    padding: 0 1em;
    &:nth-child(1){ width: 6.5%!important; }
    &:nth-child(2){ width: 17%!important; }
    &:nth-child(3){ width: 57%!important; }
    &:nth-child(4){ width: 10%!important; }
    &:last-child{ min-width: 6em; }
}