@import "../../../styles/main.scss";

.recover-private-key-modal {
  .header {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between!important;

    .title {
      font-family: $font-CoconPro!important;
      font-size: 24px;
      color: #4a4a4a!important;
    }

    i {
      color: $icon-gray!important;
      -webkit-text-stroke: unset!important;
    }
    i:hover {
      cursor: pointer;
    }
  }

  .body {
    display: flex;

    p {
      font-family: $font-OpenSans;
      font-size: 21px;
      letter-spacing: normal;
      color: #626262;
    }

    .input-container {
      background-color: white!important;
      height: 54px!important;
      border: solid 2px $light-gray-fill;
      margin-top: 41px;

      input {
        background-color: white;
        height: 50px;
        border-radius: 7px;
        padding-left: 15px;
        padding-right: 15px;
        font-family: $font-OpenSans;
      }
    }

    input:focus {
      outline: none;
    }

    .fix-height {
      height: 53px;
      width: 100%;
    }

    .mnemonic-container {
      display: block;

      .word {
        // word container
        display: inline-flex;
        padding: 8px;
        margin: 16px;
        border-radius: 10px;
        border: dashed 1px #c1c1c1;

        //text
        font-family: $font-Comfortaa;
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #767676;
      }
    }
  }
  @media (max-width: 400px) {
    body {
      padding-right: 0;
      padding-left: 0;
    }
  }
}