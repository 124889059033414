@import "../../../styles/main.scss";

.registrar-new-account-screen {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: white;

  .main-column {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;

    .header {
      width: unset;
      height: unset;
      padding: 57px 0 57px 71px;
    }

    .registrar-institution-form {
      flex: 1;
      justify-content: center;
      padding-left: 84px;
    }

    .new-account-background {
      height: 100vh;
      align-self: flex-end;
    }
  }

  @media (max-width: 1200px) {
    .main-column {
      // hides the right column
      &.right {
        display: none;
      }

      .registrar-institution-form {
        padding-left: 57px;
        padding-right: 57px;

        .button {
          align-self: center;
        }
      }
    }
  }
}