@import "../../../styles/main.scss";

.screen-quick-access-card {
  display: flex;
  flex: 1;
  flex-direction: column;
  border-radius: 18px 18px 0 0;
  box-shadow: 0 2px 14px 0 rgba(196, 191, 191, 0.5);
  background-color: #ffffff;
  max-width: 591px;

  .title {
    font-family: $font-Comfortaa;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $nexus-secondary-green;
    padding: 26px 48px 16px 48px;
  }

  .screen-image-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 390px;

    .button {
      position: absolute;
    }

    img {
      width: 100%;
      object-fit: cover;
    }
  }
}
