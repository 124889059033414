@import "../../../styles/main.scss";
.select-branch-modal {
  .modal {
    .content {
      padding: 49px 75px 36px 55px;
      max-width: 675px;
      margin-left: 15px;
      margin-right: 15px;
      .title {
        font-family: $font-CoconPro;
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $title-color;
      }
      .subtitle {
        font-family: $font-Comfortaa;
        font-size: 20px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: normal;
        color: $title-color;
        margin-top: 16px;
        margin-bottom: 16px;
      }
      .no-branches-found {
        font-family: $font-Comfortaa;
        font-size: 15px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: normal;
        color: $icon-error;
        margin-top: 10px;
        margin-bottom: 10px;
      }
      .branch-selector {
        display: flex;
        height: 42px;
        border-radius: 4px;
        border: solid 1px #777777;
        background-color: #ffffff;
        padding: 13px 28px 13px 28px;
        margin-top: 16px;

        span {
          font-family: $font-Comfortaa;
          font-size: 14px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #013337;
        }
      }

      .branch-selector:hover {
        cursor: pointer;
        background-color: #e8e8e8;
      }

      .button {
        margin-top: 51px;
        align-self: center;
      }
    }
  }
  @media (max-width: 600px) {
    .modal {
      .content {
        margin-left: 15px;
        margin-right: 15px;
        padding: 49px 30px 36px 30px;
      }
    }
  }
}